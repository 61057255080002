<template>
  <div
    v-if="listing"
    :class="[
      {
        'listing-form-widget-mobile': true,
        vatable: !listing.vatable,
      },
      `lp-from-widget-mobile--${$q.screen.name}`,
    ]"
  >
    <div class="col text-left">
      <template v-if="!listingMetaData?.priceUponRequest">
        <PriceChangeIndicator
          v-if="!cans.includes('price-reserved')"
          :price-before="listing.originalPrice"
          :price-current="listing.price"
        />

        <div
          v-else
          class="listing-form-widget-mobile__price-reserved"
          v-text="t('section.formWidget.priceReserved')"
        />

        <div class="listing-form-widget-mobile__header-price-container">
          <span
            :style="listing.listingInfo.statuses.acquired ? 'text-decoration: line-through' : ''"
            class="listing-form-widget-mobile__header-price-text"
            v-text="formatPrice(listing.price)"
          />

          <span
            v-if="listing.vatable"
            class="listing-form-widget-mobile__header-price-vat-text"
            v-text="vatText"
          />

          <span
            v-if="configApp.squareMeterPriceVisible"
            class="listing-form-widget-mobile__header-price-sqm-text"
            :class="{ row: $q.screen.width < 420 && listing.auction?.currentRound }"
            v-text="`${formatPrice(listing.squareMetrePrice)} / m²`"
          />
        </div>
      </template>

      <span v-else class="text-h4 text-secondary" v-text="$t('priceUponRequest')" />
    </div>

    <div v-if="listing?.auction" class="listing-form-widget-mobile__header-auction-round-container">
      <span
        class="self-end text-accent text-weight-medium"
        style="font-size: 0.875rem"
        v-text="
          `${ordinal(listing.auction.currentRound.number, $i18n.locale)} ${format.capitalize(
            $t('round')
          )}`
        "
      />

      <div class="flex items-center">
        <q-icon color="primary" class="q-mr-xs" name="calendar" style="stroke-width: 2px" />
        <span class="text-weight-bolder text-secondary" v-text="auctionDateText" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { format } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import PriceChangeIndicator from '@/components/Reusable/PriceChangeIndicator.vue';
import { useTheme } from '@/composables/theme';
import configApp from '@/config/app.json';
import { useVarPool } from '@/elr/listing_page/var_pool';
import useListingStore from '@/store/modules/listing';
import { ordinal } from '@/utils/number';
import { replaceAccentedVowels } from '@/utils/string';

const { cans } = useVarPool();

const { t, locale } = useI18n();
const { listing, listingMetaData } = storeToRefs(useListingStore());
const { formatPrice, getComponentConfig } = useTheme();
const { CtaWidget: config } = getComponentConfig();

const auctionDateText = computed(() => {
  if (!listing.value || !listing.value.auction) return '';

  if (listing.value.auction?.dateToBeAnnounced) {
    return replaceAccentedVowels(t('section.formWidget.auctionDateToBeAnnounced')).toUpperCase();
  }

  const dateLocal = dayjs(listing.value.auction.currentRound.auctionDate)
    .utc(true)
    .tz()
    .locale(locale.value);

  return dateLocal.format(config.auctionRound.dateFormat);
});

const vatText = computed(() => {
  const vType = listing.value?.listingInfo.price.vatType;

  return t(`section.formWidget.vat.${vType}`);
});
</script>

<style lang="scss">
// TODO: Export CSS per client
@use '@/css/color_pallette' as c;

.listing-form-widget-mobile {
  display: flex;
  align-items: center;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem;
  line-height: 1;

  .listing-form-widget-mobile__header-price-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: baseline;
  }

  .listing-form-widget-mobile__header-price-text {
    padding-right: 0.25rem;
    font-size: 1.5rem;
    font-weight: 800;
    color: c.$secondary;
  }

  .listing-form-widget-mobile__header-price-vat-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: c.$secondary;
    text-transform: uppercase;
  }

  .listing-form-widget-mobile__header-price-sqm-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: c.$accent;
  }

  &:not(.lp-from-widget-mobile--xs) {
    .listing-form-widget-mobile__header-price-text {
      font-size: 2rem;
    }
  }

  .listing-form-widget-mobile__header-auction-round-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .pci {
    margin-bottom: 0.5rem;
  }
}
</style>
