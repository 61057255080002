import axios from 'axios';
import { ref } from 'vue';

import { API_BASE_URL, API_VERSION } from '@/config/appEnvs';

const error = ref(false);

const apiClient = axios.create({
  baseURL: `${API_BASE_URL}/${API_VERSION}`,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  withCredentials: true,
  withXSRFToken: true,
});

export const useApiClient = () => {
  return {
    apiClient,
    error,
  };
};
