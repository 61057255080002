<template>
  <div class="column property-code-wrapper">
    <div class="sp-filter__label" v-text="formatLabel(label)" />
    <q-input
      v-model.trim="propertyCode"
      :placeholder="t('SPFiltersPropertyCode.fields.propertyCode.placeholder')"
      input-class="text-secondary"
      outlined
      no-error-icon
      hide-bottom-space
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/searchPage.json';
import { formatLabel } from '@/utils/string';

interface Props {
  modelValue: string | string[] | null;
  label?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: 'searchById',
  modelValue: () => [],
});

const emit = defineEmits<{
  (e: 'update:modelValue', p: Props['modelValue']): void;
}>();

const { t } = useI18n(translations);

const propertyCode = computed({
  get: () => (Array.isArray(props.modelValue) ? props.modelValue[0] : props.modelValue),
  set: v => {
    emit('update:modelValue', Array.isArray(v) ? v[0] : v);
  },
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

:deep(.q-field__control) {
  border-radius: map.get($radius-sizes, 'md') !important;
}
</style>
