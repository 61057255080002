import 'quasar/src/css/index.sass';
import '@/css/index.scss';

import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';
import { Cookies, Meta, Notify, Quasar } from 'quasar';
import { createApp, h } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import App from '@/App.vue';
import GlobalAvatar from '@/components/Global/GlobalAvatar.vue';
import GlobalButton from '@/components/Global/GlobalButton.vue';
import {
  API_BASE_URL,
  API_VERSION,
  APP_BASE_URL,
  GTM_ID,
  RECAPTCHA_SITE_KEY,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_ENVIRONMENT,
  SENTRY_SAMPLE_RATE,
} from '@/config/appEnvs';
import i18n from '@/i18n';
import router from '@/router';
import pinia from '@/store';
import iconSet from '@/utils/icons';

const app = createApp({
  created() {
    this.$q.iconMapFn = (iconName: keyof typeof iconSet) => ({ icon: iconSet[iconName] });
  },
  render: () => h(App),
});

if (SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      //  Sentry.replayIntegration(),
      Sentry.vueIntegration({
        app,
        attachErrorHandler: true,
        attachProps: true,
        tracingOptions: {
          trackComponents: true,
        },
      }),
    ],
    attachStacktrace: true,
    tracePropagationTargets: [`${API_BASE_URL}/${API_VERSION}`, APP_BASE_URL, /^\//],
    tracesSampleRate: Number(SENTRY_SAMPLE_RATE || '1.0'),
    environment: SENTRY_ENVIRONMENT || import.meta.env.MODE,
  });
}

app
  .component('GlobalButton', GlobalButton)
  .component('GlobalAvatar', GlobalAvatar)
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY, loaderOptions: { autoHideBadge: true } })
  .use(Quasar, { plugins: { Cookies, Meta, Notify } })
  .use(createGtm({ defer: true, id: GTM_ID, vueRouter: router }))
  .use(VueDOMPurifyHTML, {
    default: { USE_PROFILES: { html: true } },
    hooks: {
      // Keep the target blank for links but add rel noopener so that the browser navigates to the target
      // without granting the new browsing context access to the document that opened it
      afterSanitizeAttributes: (currentNode: Element) => {
        if ('target' in currentNode) {
          currentNode.setAttribute('target', '_blank');
          currentNode.setAttribute('rel', 'noopener');
        }
      },
    },
  });

app.mount('#app');
