import { storeToRefs } from 'pinia';
import type { WatchStopHandle } from 'vue';
import { nextTick, ref, watch } from 'vue';

import { useApiMe } from '@/composables/api/me';
import useAuthStore from '@/store/modules/auth';
import useThemeStore from '@/store/modules/theme';
import type { DestroyFavoriteVariables } from '@/types/api/me';

export const useFavorites = () => {
  const { destroyFavorite, destroyFavorites, storeFavorite } = useApiMe();

  const { elListingItemFavoritesBtnBoundingsWidth } = storeToRefs(useThemeStore());
  const { dialogAuth, user } = storeToRefs(useAuthStore());

  const loading = ref(false);
  const loadingRemoveAll = ref(false);
  const watchAuthUserHandler = ref<WatchStopHandle>();

  const isFavorite = (listingId: number | string) => !!user.value?.favorite_ids.includes(listingId);

  const setUserFavorites = (listingId: number | string) => {
    user.value?.favorite_ids.push(listingId);
  };

  const removeFavorite = (listingId: DestroyFavoriteVariables) =>
    destroyFavorite(listingId).then(() => {
      if (!user.value) return;

      user.value.favorite_ids = user.value.favorite_ids.filter(id => id !== listingId);
    });

  const markListingAsFavorite = async (listingId: number | string) => {
    if (!user.value) {
      dialogAuth.value = true;

      if (!watchAuthUserHandler.value) {
        nextTick(() => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          watchAuthUser(listingId);
        });
      }

      return Promise.resolve(true);
    }

    loading.value = true;
    let promise;

    if (isFavorite(listingId)) {
      promise = removeFavorite(listingId);
    } else {
      promise = storeFavorite({ listingId }).then(() => {
        user.value?.favorite_ids.push(listingId);
      });
    }

    return promise.finally(() => {
      loading.value = false;
    });
  };

  const watchAuthUser = (listingId: number | string) => {
    watchAuthUserHandler.value = watch(dialogAuth, v => {
      if (!v && user.value && !user.value.favorite_ids.includes(listingId)) {
        markListingAsFavorite(listingId);

        if (watchAuthUserHandler.value) {
          watchAuthUserHandler.value();
          watchAuthUserHandler.value = undefined;
        }
      } else if (watchAuthUserHandler.value) {
        watchAuthUserHandler.value();
        watchAuthUserHandler.value = undefined;
      }
    });
  };

  const removeAllFavorites = () => {
    loadingRemoveAll.value = true;

    return destroyFavorites()
      .then(() => {
        user.value?.favorite_ids.splice(0);
      })
      .finally(() => {
        loadingRemoveAll.value = false;
      });
  };

  return {
    isFavorite,
    elListingItemFavoritesBtnBoundingsWidth,
    loading,
    loadingRemoveAll,
    markListingAsFavorite,
    removeFavorite,
    removeAllFavorites,
    setUserFavorites,
  };
};
