import { Cookies } from 'quasar';

import { useConfig } from '@/composables/config';

type CookieOptions = Parameters<Cookies['set']>[2];

const defaultOptions: CookieOptions = {
  sameSite: 'Lax',
  secure: process.env.NODE_ENV === 'production',
};

export const useCookies = () => {
  const { configCookie } = useConfig();

  const setCookieItem = (key: string, value: string, options: CookieOptions = {}) => {
    Cookies.set(key, value, { ...defaultOptions, ...options, path: '/' });
  };

  const getCookieItem = (key: string) => Cookies.get(key);

  const setCookieLocale = (value: string) => {
    const domain = window.location.hostname.replace('marketplace', '');

    const expires = 6 * 30;

    setCookieItem(configCookie.nameLocale, value, {
      domain,
      expires,
    });
  };

  const setCookieUtmSource = (value: string) => {
    setCookieItem(configCookie.nameUtmSource, value);
  };

  return {
    getCookieItem,
    setCookieItem,
    setCookieLocale,
    setCookieUtmSource,
  };
};
