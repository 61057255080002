<template>
  <div v-if="listing?.propertyInfo.address.fullAddress" class="listing-address">
    <span class="listing-body__text--subtitle" v-text="t('section.location.area.address')" />

    <div class="listing-address__container">
      <q-icon class="listing-address__icon" size="1.5rem" name="mapMarker" />

      <span class="listing-address__text" v-text="listing.propertyInfo.address.fullAddress" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/listingPage.json';
import useListingStore from '@/store/modules/listing';

const { t } = useI18n(translations);

const { listing } = storeToRefs(useListingStore());
</script>
