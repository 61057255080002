import 'dayjs/locale/el';
import 'dayjs/locale/en-gb';

import type { OpUnitType } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export const toDate = (
  date: Date | string | dayjs.Dayjs,
  useUtc = false,
  format = 'DD/MM/YYYY',
  locale = 'en'
) => {
  if (date == null) return null;
  const instance = dayjs(date).locale(locale);

  return useUtc ? instance.utc().format(format) : instance.format(format);
};

export const toDateTime = (
  date: Date | string | dayjs.Dayjs,
  useUtc = false,
  format = 'DD/MM/YYYY HH:mm',
  locale = 'en'
) => {
  return toDate(date, useUtc, format, locale);
};

/**
 * Only accepts dates that are within the current month (or later)
 * and within current year (or later)
 */
export const filterPastDates = (dates: string[]) => {
  const currentDate = new Date();

  const validDates = dates.filter(date => {
    const parsedDate = new Date(date);

    return (
      parsedDate > currentDate ||
      (parsedDate.getFullYear() === currentDate.getFullYear() &&
        parsedDate.getMonth() >= currentDate.getMonth())
    );
  });

  return validDates;
};

/**
 * Docs: https://day.js.org/docs/en/display/difference
 * @returns number based on unit value. Example: 'days' will return '10', 'milliseconds' will return '1060' etc.
 */
export const dateTimeDiff = (
  firstDate: Date | string | dayjs.Dayjs,
  secondDate: Date | string | dayjs.Dayjs,
  unit?: OpUnitType
): number => {
  return dayjs(firstDate).diff(dayjs(secondDate), unit);
};

/**
 * Docs: https://day.js.org/docs/en/query/is-before
 * @returns boolean
 */
export const isBeforeDate = (
  firstDate: Date | string | dayjs.Dayjs,
  secondDate: Date | string | dayjs.Dayjs,
  unit?: OpUnitType
): boolean => {
  return dayjs(firstDate).isBefore(dayjs(secondDate), unit);
};

/**
 * @returns Obj of days, daysShort, months, monthsShort to locale
 */
export const calendarLocaleData = (locale = 'en') => {
  dayjs.locale(locale);
  return {
    days: dayjs.weekdays(),
    daysShort: dayjs.weekdaysShort(),
    months: dayjs.months(),
    monthsShort: dayjs.monthsShort(),
  };
};
