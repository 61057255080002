<template>
  <div class="listing-lro">
    <div class="listing-body__text--title" v-text="t('section.lro.title')" />

    <div v-if="requiresAuth && !user" class="listing-lro__unlock-container">
      <div class="listing-lro__text" v-text="t('section.lro.subtitle')" />

      <q-btn class="listing-lro__btn" v-bind="btnPropsSubmit" @click="onClick" />
    </div>

    <div
      v-else-if="lro && (lro.lroIds.length || lro.address)"
      class="listing-lro__details-container"
    >
      <div v-if="lro.address" class="listing-lro-details__address" v-text="lro.address" />

      <div v-for="(item, i) in lro.lroIds" :key="i" class="listing-lro-details">
        <template v-for="(val, key) in item" :key="key">
          <div v-if="val" class="listing-lro-details__info">
            <span class="listing-lro-details__info-text" v-text="`${t(`section.lro.${key}`)}`" />
            <span class="listing-lro-details__info-value" v-text="val" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useFeatureConfig } from '@/composables/featureConfig';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import translations from '@/i18n/translations/components/listingPage.json';
import useAuthStore from '@/store/modules/auth';
import useListingStore from '@/store/modules/listing';

const { getFeature } = useFeatureConfig();

const requiresAuth = computed(() => getFeature('identificationDetails')?.options.requiresAuth);

const { authMeta, dialogAuth, user } = storeToRefs(useAuthStore());
const { lro } = storeToRefs(useListingStore());

const { t } = useI18n({ ...translations, ...tFormStepsFactory, useScope: 'global' });

const onClick = () => {
  authMeta.value.email.title = t('title.lroLogin');
  authMeta.value.email.subtitle = t('subtitle.lroLogin');

  dialogAuth.value = true;
};

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'white',
  label: t('btn.lroUnlock.label'),
  noCaps: true,
  outline: true,
  textColor: 'primary',
  unelevated: true,
  icon: 'unlock',
}));
</script>
