<template>
  <SPFiltersBtnMore
    :clear-btn-visible="clearBtnVisible"
    :class="{
      'search-filter-wrapper-btn search-filters-collapsed': true,
      'search-filter-wrapper-btn--has-value': hasFilterValue,
      'show-only-map': searchStore.showOnlyMap,
    }"
    :model-value="searchStore.filters"
    :filter-list="filtersKeysToShow"
    :filter-options="filtersToShow"
    :inside-map="insideMap"
    :disabled="searchStore.loadingState"
    @update:model-value="updateFilters($event)"
  />
</template>

<script setup lang="ts">
import { Screen } from 'quasar';
import { computed } from 'vue';

import SPFiltersBtnMore from '@/components/SearchPage/Filters/SPFiltersBtnMore.vue';
import useAppStore from '@/store/modules/app';
import useSearchStore from '@/store/modules/search';
import type { SearchFilter } from '@/types';
import { isBlank } from '@/utils/util';

interface Props {
  insideMap?: boolean;
  clearBtnVisible?: boolean;
}

const props = defineProps<Props>();

const appStore = useAppStore();
const searchStore = useSearchStore();

const filtersToShow = computed(() =>
  props.insideMap || Screen.lt.md ? appStore.allFilters : appStore.filters.secondary
);

const filtersKeysToShow = computed(() =>
  props.insideMap || Screen.lt.md ? appStore.allFilterKeys : appStore.secondaryFilterKeys
);

const hasFilterValue = computed(() =>
  Object.keys(appStore.filters.secondary).some(item =>
    Object.keys(searchStore.filters).includes(item)
  )
);

/**
 * * Keep only the filters that are not empty or filters that are empty but already exist in the store.
 */
const updateFilters = (event: SearchFilter) => {
  const filteredEvents = Object.entries(event).filter(([key, value]) => {
    return !isBlank(value) || (isBlank(value) && key in searchStore.filters);
  });

  const filteredEvent = Object.assign(
    {},
    ...filteredEvents.map(([key, value]) => ({ [key]: value }))
  );
  searchStore.previousFilters = { ...searchStore.filters };
  searchStore.$patch(() => {
    searchStore.filters = { ...filteredEvent };
  });
};
</script>
