<template>
  <div :class="[`page--${route.name?.toString()}`, `screen--${$q.screen.name}`]">
    <RouterView v-if="hydrated" />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useBoot } from '@/composables/boot';
import useSearchStore from '@/store/modules/search';

const { bootApp } = useBoot();
const searchStore = useSearchStore();

const route = useRoute();

const itemsToPersist = ['showOnlyMap', 'searchAsIMove'] as const;

onMounted(() => {
  if (!window?.localStorage) return;

  itemsToPersist.forEach(item => {
    try {
      const localStorageItem = window.localStorage.getItem(item);

      if (localStorageItem) {
        searchStore[item] = JSON.parse(localStorageItem);
      }
    } catch (e) {
      console.error(e);
    }
  });
});

const hydrated = ref(false);

onBeforeMount(async () => {
  await bootApp();
});

onMounted(() => {
  hydrated.value = true;
});
</script>

<style lang="scss">
.q-notification__actions {
  align-self: start !important;
  margin: 0 !important;
}
</style>
