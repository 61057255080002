<template>
  <div class="l-auction-siblings-container">
    <div v-for="(sibling, i) in auctionSiblings" :key="i" class="l-auction-sibling">
      <div class="l-auction-sibling__content">
        <q-icon v-if="icons.auctionSibling" :name="icons.auctionSibling" />
        <div v-text="`${formatLabel(camelCase(sibling.type))}`" />
        <div v-text="`${sibling.size}m²`" />
        <div v-text="formatPrice(sibling.startingPrice)" />
      </div>

      <q-btn
        class="l-auction-sibling__btn"
        color="white"
        text-color="primary"
        unelevated
        no-caps
        :label="$t('viewProperty')"
        :to="{
          name: 'listing',
          params: { id: sibling.listingId },
        }"
        type="a"
        target="_blank"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import camelCase from 'lodash/camelCase';
import { storeToRefs } from 'pinia';

import { useTheme } from '@/composables/theme';
import useListingStore from '@/store/modules/listing';
import { formatLabel } from '@/utils/string';

const { formatPrice, icons } = useTheme();

const { auctionSiblings } = storeToRefs(useListingStore());
</script>

<style lang="scss">
@use 'sass:map';

.l-auction-siblings-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  background-color: $util-3;
  border-radius: map.get($radius-sizes, sm);

  .l-auction-sibling {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;

      a {
        width: 100%;
      }
    }
  }

  .l-auction-sibling__content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.6;

    .q-icon {
      width: 2rem;
      height: 2rem;
    }

    > div {
      position: relative;

      &:not(:last-child):after {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 2px;
        height: 75%;
        content: '';
        background: $secondary;
        transform: translate3d(0.5rem, -50%, 0);
      }
    }
  }

  .l-auction-sibling__btn {
    border: 1px solid $primary;
    border-radius: map.get($radius-sizes, md);
  }
}
</style>
