import { useConfig } from '@/composables/config';
import { useCookies } from '@/composables/useCookies';
import { getParamFromQueryString } from '@/utils/queryString';

export const useUtmSource = () => {
  const { getCookieItem } = useCookies();
  const { configCookie } = useConfig();

  const resolveUtmSource = () => {
    const gclid = getParamFromQueryString(window.location.search, 'gclid');
    if (gclid) return 'google';

    return getCookieItem(configCookie.nameUtmSource);
  };

  return { resolveUtmSource };
};
