import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import configApp from '@/config/app.json';
import useAppStore from '@/store/modules/app';

const useLocalizedLinks = () => {
  const { fallbackLocale, locale } = useI18n();

  const { config } = storeToRefs(useAppStore());

  const defaultLocale = computed(() => config.value?.locale.default);

  const generateLocalizedUrl = (url: string) => {
    const { origin } = new URL(url);
    const prefix = locale.value !== fallbackLocale.value ? locale.value : '';
    return prefix === '' ? url : url.replace(origin, `${origin}/${prefix}`);
  };

  const listingLocalizedLink = (id: number | string) => {
    const prefix = locale.value === defaultLocale.value ? '' : `${locale.value}/`;
    return `/${prefix}listings/${id}`;
  };

  const listYourPropertyLocalizedLink = () => {
    const prefix = locale.value === defaultLocale.value ? '' : `${locale.value}/`;
    return `/${prefix}list-property`;
  };

  const localizedCompanyUrl = computed(() => {
    const localeLocal = locale.value !== fallbackLocale.value ? locale.value : '';

    const homeUrlLastChar = configApp.company.links.home.charAt(
      configApp.company.links.home.length - 1
    );

    let urlBase = configApp.company.links.home;

    if (homeUrlLastChar === '/') {
      urlBase = configApp.company.links.home.slice(0, -1);
    }

    const urlLocal = localeLocal === '' ? urlBase : `${urlBase}/${localeLocal}`;

    return process.env.NODE_ENV === 'development' ? '/' : urlLocal;
  });

  const localizedTermsUrl = computed(() => generateLocalizedUrl(configApp.company.links.terms));

  const localizedPrivacyUrl = computed(() => generateLocalizedUrl(configApp.company.links.privacy));

  return {
    listingLocalizedLink,
    listYourPropertyLocalizedLink,
    localizedCompanyUrl,
    localizedTermsUrl,
    localizedPrivacyUrl,
  };
};

export default useLocalizedLinks;
