<template>
  <q-card v-if="listing" flat class="lp-l-media-gallery bg-white column fullscreen">
    <q-bar class="lp-l-media-gallery--header">
      <div class="col row justify-center">
        <q-tabs
          v-model="tab"
          active-bg-color="primary"
          active-color="white"
          align="justify"
          dense
          indicator-color="transparent"
        >
          <template v-for="control in mediaControls" :key="control.value">
            <q-tab
              v-if="control.visible"
              class="q-px-lg q-mx-xs"
              :name="control.value"
              :ripple="false"
            >
              <span class="text-body2-bold text-capitalize" v-text="control.label" />
            </q-tab>
          </template>
        </q-tabs>
      </div>

      <q-btn
        class="lp-l-header--close-btn"
        icon="cancel"
        :label="$t('btnActions.close')"
        no-caps
        unelevated
        @click="emit('close')"
      />
    </q-bar>

    <q-card-section class="col content-container">
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="photos" class="row justify-center q-pa-none">
          <q-carousel
            id="photos"
            v-model="photos"
            animated
            :arrows="listing.images.photos.length > 1"
            class="content"
            control-color="primary"
            control-type="outline"
            infinite
            swipeable
            :thumbnails="listing.images.photos.length > 1"
            transition-next="arrow_left"
            transition-prev="arrow_right"
          >
            <q-carousel-slide
              v-for="cPhoto in listing.images.photos"
              :key="cPhoto.id"
              class="carousel-image-container"
              :img-src="cPhoto.sizes[imageSize]"
              :name="cPhoto.id"
            />
          </q-carousel>
        </q-tab-panel>

        <q-tab-panel
          v-if="!!listing.images.mapPhotos"
          class="row justify-center q-pa-none"
          name="mapPhotos"
        >
          <q-carousel
            id="map-photos"
            v-model="mapPhotos"
            animated
            :arrows="listing.images.mapPhotos.length > 1"
            class="content"
            control-color="primary"
            control-type="outline"
            infinite
            swipeable
            :thumbnails="listing.images.mapPhotos.length > 1"
            transition-prev="arrow_right"
            transition-next="arrow_left"
          >
            <q-carousel-slide
              v-for="cMapPhoto in listing.images.mapPhotos"
              :key="cMapPhoto.id"
              :name="cMapPhoto.id"
              :img-src="cMapPhoto.sizes[imageSize]"
              class="carousel-image-container"
            />
          </q-carousel>
        </q-tab-panel>

        <q-tab-panel
          v-if="hasMatterport"
          class="row justify-center q-pa-none"
          name="matterportVideo"
        >
          <iframe
            id="matterport-video"
            class="content"
            frameBorder="0"
            :src="matterportVideoUrl"
            title="matterport video"
          />
        </q-tab-panel>

        <q-tab-panel v-if="areaVideoUrl" name="areaVideo" class="row justify-center q-pa-none">
          <iframe
            id="area-video"
            class="content"
            frameBorder="0"
            :src="areaVideoUrl"
            title="area video"
          />
        </q-tab-panel>

        <q-tab-panel v-if="hasDroneVideo" name="droneVideo" class="row justify-center q-pa-none">
          <iframe
            id="drone-video"
            class="content"
            frameBorder="0"
            :src="droneVideoUrl"
            title="drone video"
          />
        </q-tab-panel>

        <q-tab-panel name="floorPlans" class="row justify-center q-pa-none">
          <q-carousel
            id="floor-plans"
            v-model="floorPlans"
            animated
            :arrows="listing.images.floorPlans.length > 1"
            class="content"
            control-color="primary"
            control-type="outline"
            infinite
            swipeable
            :thumbnails="listing.images.floorPlans.length > 1"
            transition-next="arrow_left"
            transition-prev="arrow_right"
          >
            <q-carousel-slide
              v-for="(cFloorPlan, i) in listing.images.floorPlans"
              :key="i"
              class="carousel-image-container"
              :img-src="cFloorPlan.sizes[imageSize]"
              :name="i"
            />
          </q-carousel>
        </q-tab-panel>
      </q-tab-panels>
    </q-card-section>
  </q-card>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

import { type MediaControl, useListingMedia } from '@/composables/listingMedia';
import { useTheme } from '@/composables/theme';
import useGTM from '@/composables/useGTM';
import useImageSizeResolver from '@/composables/useImageSizeResolver';
import useListingStore from '@/store/modules/listing';

const slideId = defineModel<number>('slide', { default: -1, required: false });
const tab = defineModel<MediaControl['value']>('tab');

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const { listing } = storeToRefs(useListingStore());
if (!listing.value) throw new Error('Listing is undefined');

const { barStyle, thumbStyle } = useTheme();
const { imageSize } = useImageSizeResolver();
const {
  areaVideoUrl,
  droneVideoUrl,
  hasDroneVideo,
  hasMatterport,
  matterportVideoUrl,
  mediaControls,
  photosMerged,
} = useListingMedia(listing.value);

const floorPlans = ref(0);

const findSlideIdByCollection = (collection: MediaControl['value']) => {
  const filteredCollection = photosMerged.value.filter(pm => pm.collection === collection);
  const foundSlide = filteredCollection.find(el => el.id === slideId.value);
  const defaultSlide = filteredCollection?.[0];

  return foundSlide?.id || defaultSlide?.id || -1;
};

const photos = ref(findSlideIdByCollection('photos'));
const mapPhotos = ref(findSlideIdByCollection('mapPhotos'));

watch(
  () => tab.value,
  v => {
    switch (v) {
      case 'droneVideo':
        useGTM('drone_video_opened');
        break;
      case 'areaVideo':
        useGTM('area_video_opened');
        break;
      case 'floorPlans':
        useGTM('floor_plans_opened');
        break;
      case 'matterportVideo':
        useGTM('3d_tour_opened');
        break;
      default:
        useGTM('image_gallery_opened');
        break;
    }
  },
  { immediate: true }
);
</script>
<style lang="scss">
@use 'sass:map';

.lp-l-media-gallery {
  .content-container {
    padding-top: 3.5rem;
  }

  .lp-l-media-gallery--header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 3.5rem;
    background: white;
    box-shadow: 0 4px 8px rgb(219 225 245 / 25%) !important;

    .lp-l-header--close-btn {
      position: absolute;
      right: 0;
      margin: 0 1rem;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.6;
      color: $primary;
      text-transform: capitalize;

      .q-icon {
        width: 1.5rem;
        height: 1.5rem;

        &.on-left {
          margin-right: 0;
        }
      }
    }

    .q-tab {
      transition: none;

      &.q-tab--active {
        border: 1px solid $primary;
      }
    }
  }

  .q-carousel {
    .q-carousel__slide {
      background-color: $util-3;

      &.carousel-image-container {
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .q-carousel__thumbnail {
      margin: 0;
      background-color: $util-3;
    }

    .q-carousel__navigation--thumbnails {
      position: relative;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      scrollbar-color: v-bind('thumbStyle.backgroundColor') v-bind('barStyle.backgroundColor');
      scrollbar-width: thin;
      background: white;

      .q-carousel__thumbnail--inactive {
        opacity: 0.4;
      }

      .q-carousel__navigation-inner {
        position: absolute;
        position: relative;
        inset: 50% 0 0;
        display: flex;
        flex-direction: row;
        gap: 0.3125rem;
        align-items: center;
        transform: translateY(-50%);

        img {
          width: 60px;
          height: 50px;
        }
      }
    }
  }

  .content {
    width: 90vw;
    max-width: 1920px;
    height: calc(100vh - 3.5rem);
  }

  .q-tab {
    border: 1px solid $util-2;
    border-radius: map.get($radius-sizes, 'xl');
  }

  #photos,
  #map-photos,
  #floor-plans {
    padding: 50px 60px 80px;

    .q-icon {
      font-size: 12px;
    }

    .q-carousel__slide {
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  #matterport-video,
  #area-video,
  #drone-video {
    padding: 50px 60px;
  }
}
</style>
