<template>
  <div v-if="listing" class="listing-pois-map">
    <div class="listing-body__text--title" v-text="t('section.location.pois.title')" />

    <div v-if="!poisCarouselView" class="row q-mb-sm">
      <div
        v-for="(poi, index) in poisMap"
        :key="`poi-${index}`"
        :class="{ 'col row poi-type q-mb-sm': true, selected: selected?.type === poi.type }"
      >
        <q-btn
          class="col q-pa-md poi-btn"
          dense
          no-caps
          outline
          unelevated
          @click="select(poi.type)"
        >
          <div class="row no-wrap items-center">
            <q-icon :name="poi.type" size="20px" class="q-mr-xs" />
            <div
              class="text-center"
              v-text="t(`section.location.pois.labels.${poi.type}`, { count: poi.places.length })"
            />
          </div>
        </q-btn>
      </div>
    </div>

    <q-carousel
      v-else
      v-model="slide"
      animated
      arrows
      class="text-white"
      control-color="secondary"
      height="100px"
      keep-alive
      swipeable
      transition-next="slide-left"
      transition-prev="slide-right"
    >
      <q-carousel-slide
        v-for="(poiChunk, i) in poisTabs"
        :key="i"
        class="row no-wrap items-center justify-center"
        :name="`slide-${i}`"
      >
        <div
          v-for="(poi, poiIndex) in poiChunk"
          :key="`poi-${poiIndex}`"
          :class="{ 'row poi-type': true, selected: selected?.type === poi.type }"
        >
          <q-btn
            class="col poi-btn q-pa-sm"
            dense
            no-caps
            outline
            unelevated
            @click="select(poi.type)"
          >
            <div :class="['text-body2', poisCarouselView ? 'col' : 'row items-center no-wrap']">
              <q-icon :name="poi.type" size="20px" class="q-mr-xs" />
              <div
                class="text-center"
                v-text="
                  `${poi.places.length} ${t(`section.location.pois.labels.${poi.type}`, { count: poi.places.length })}`
                "
              />
            </div>
          </q-btn>
        </div>
      </q-carousel-slide>
    </q-carousel>

    <GoogleMap
      ref="elGMap"
      :api-key="MAP_KEY"
      :center="mapCenter"
      class="rounded-borders overflow-hidden"
      :fullscreen-control="false"
      gesture-handling="cooperative"
      map-type-control
      :map-type-control-options="configMap.poisMapOptions.mapTypeControlOptions"
      :map-type-id="configMap.poisMapOptions.mapTypeId"
      :max-zoom="18"
      :min-zoom="6"
      :rotate-control="false"
      :scale-control="false"
      :scrollwheel="false"
      :street-view-control="false"
      style="width: 100%; height: 380px"
      :styles="configMap.mapStyles"
      :zoom="configMap.mapOptions.zoomInitial"
      :zoom-control="$q.screen.gt.sm"
    >
      <template v-if="elGMap?.map && elGMap.api">
        <SPMapDirectionControl
          :listing-lat-lng="listing.address.coordinates"
          :position="configMap.poisMapOptions.mapControlsPosition.directions"
        />
        <SPMapHtmlMarker
          alignment="center"
          :el-g-map="elGMap"
          :marker="listing.address.coordinates"
          :z-index="51"
        >
          <div class="poi-property-marker">
            <q-icon
              :color="configMap.mapIcons.marker.color"
              :name="configMap.mapIcons.marker.icon"
              :size="configMap.mapIcons.marker.size"
            />
          </div>
        </SPMapHtmlMarker>

        <SPMapHtmlMarker
          v-for="(marker, i) in selected?.places"
          :key="`${selected?.type}-${i}`"
          alignment="center"
          :el-g-map="elGMap"
          :marker="marker.coordinates"
          @click="togglePoiNotation(i)"
        >
          <div class="poi-marker">
            <InfoWindow
              v-if="showPoiNotation && showingPoiNotationIndex === i"
              class="flex"
              :options="poiNotationPosition(marker.coordinates)"
              @closeclick="togglePoiNotation(null)"
            >
              <div class="q-pa-xs text-caption flex items-center no-wrap">
                <div class="text-caption text-secondary text-center" v-text="marker.name" />

                <q-icon
                  class="cursor-pointer"
                  color="secondary"
                  name="cancel"
                  size="22px"
                  @click="togglePoiNotation(null)"
                />
              </div>
            </InfoWindow>

            <q-icon v-if="selected" :name="selected.type" size="14px" color="primary" />
          </div>
        </SPMapHtmlMarker>
      </template>
    </GoogleMap>
  </div>
</template>

<script lang="ts" setup>
import chunk from 'lodash/chunk';
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { GoogleMap, InfoWindow } from 'vue3-google-map';

import SPMapDirectionControl from '@/components/SearchPage/Map/Control/SPMapDirectionControl.vue';
import SPMapHtmlMarker from '@/components/SearchPage/Map/SPMapHtmlMarker.vue';
import { MAP_KEY } from '@/config/appEnvs';
import configMap from '@/config/map.json';
import translations from '@/i18n/translations/components/listingPage.json';
import useListingStore from '@/store/modules/listing';
import type { GeoPOI, POIType } from '@/types/api/proximity';

const { listing } = storeToRefs(useListingStore());

const { t } = useI18n(translations);

const poisMap = computed(() => listing.value?.pois?.map || []);

const poisTabs = computed(() => {
  let chunks = 1;

  if ((Screen.width < 1530 && Screen.width > 1200) || (Screen.width < 1200 && Screen.width > 600)) {
    chunks = 3;
  } else if (
    (Screen.width < 1300 && Screen.width > 1200) ||
    (Screen.width <= 600 && Screen.width > 440)
  ) {
    chunks = 2;
  }

  return chunk(poisMap.value, chunks);
});

const showingPoiNotationIndex = ref<number | null>(null);
const showPoiNotation = ref(false);
const selected = ref<GeoPOI>();
const mapCenter = ref<google.maps.LatLngLiteral>(configMap.mapOptions.mapCenter);
const elGMap = useTemplateRef<InstanceType<typeof GoogleMap>>('elGMap');
const slide = ref('slide-0');

const poisCarouselView = computed(() => Screen.width <= 1530);

const poiNotationPosition = (position: google.maps.LatLngLiteral) => ({
  pixelOffset: new google.maps.Size(13, 0),
  position,
});

const fitBounds = () => {
  if (!listing.value || !selected.value || !elGMap.value) return;

  const bounds = new google.maps.LatLngBounds();

  for (let i = 0; i < selected.value.places.length; i += 1) {
    bounds.extend(selected.value.places[i].coordinates);
  }

  bounds.extend(listing.value.address.coordinates);

  elGMap.value.map?.fitBounds(bounds);
};

const select = (type: POIType) => {
  selected.value = poisMap.value.find(el => el.type === type);

  showingPoiNotationIndex.value = null;
  showPoiNotation.value = false;

  fitBounds();
};

const togglePoiNotation = (i: number | null) => {
  showingPoiNotationIndex.value = i;
  showPoiNotation.value = i !== null;
};

watch([() => elGMap.value?.ready, () => poisMap.value.length], v => {
  if (v[0] && v[1]) select('school');
});
</script>
