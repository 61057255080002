import dayjs from 'dayjs';

import type {
  ListingAuction,
  ListingAuctionRound,
  ListingAuctionSiblings,
} from '@/types/api/listing';
import { isBeforeDate } from '@/utils/time';

export default class Auction {
  public readonly dateToBeAnnounced: boolean;

  public readonly nextRoundComingSoon: boolean;

  private readonly auctionRounds: ListingAuctionRound[];

  private readonly auctionSiblings?: ListingAuctionSiblings[];

  constructor(auction: ListingAuction) {
    this.auctionRounds = auction.rounds;
    this.auctionSiblings = auction.siblings;
    this.dateToBeAnnounced = auction.dateToBeAnnounced;
    this.nextRoundComingSoon = auction.nextRoundComingSoon || false;
  }

  public get rounds(): ListingAuctionRound[] {
    if (this.auctionRounds.length === 0) return [];

    const rounds = JSON.parse(JSON.stringify(this.auctionRounds));

    const sortedRounds = rounds.sort(
      (a: ListingAuctionRound, b: ListingAuctionRound) => a.number - b.number
    );

    return sortedRounds;
  }

  public get currentRound(): ListingAuctionRound {
    return this.rounds[this.rounds.length - 1];
  }

  public get firstRound(): ListingAuctionRound {
    return this.rounds[0];
  }

  public get originalPrice(): number {
    return this.firstRound.startingPrice;
  }

  public get price(): number {
    return this.currentRound.startingPrice;
  }

  public get siblings() {
    return this.auctionSiblings;
  }

  public auctionDatePassed(): boolean {
    return isBeforeDate(dayjs(this.currentRound.auctionDate).utc(true).tz(), Date(), 'day');
  }

  public biddingAvailable(): boolean {
    const workingDaysDiff = this.getWorkingDaysDiff();

    // Return true if there are at least 3 working days between the dates
    return workingDaysDiff >= 3; // TODO: move 3 to config per client
  }

  public auctionDate(): string {
    return this.currentRound.auctionDate;
  }

  public getWorkingDaysDiff(): number {
    const startDate = dayjs().toDate();
    const auctionDate = dayjs(this.currentRound.auctionDate).utc(true).tz().toDate();
    // Clone the start date to avoid modifying the original value
    const date = startDate;

    // Initialize counters
    let workingDays = 0;
    // let totalDays = 0;

    // Loop through each day between the start and end dates
    while (date <= auctionDate) {
      // Check if the current day is a working day (Monday to Friday)
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        workingDays += 1;
      }
      // totalDays += 1;
      date.setDate(date.getDate() + 1);
    }

    return workingDays;
  }
}
