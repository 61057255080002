import { computed } from 'vue';

import useSearchStore from '@/store/modules/search';
import { setQuery } from '@/utils/filterMutationsHandler';

export const useClearFilters = () => {
  const searchStore = useSearchStore();
  const hasActiveFilters = computed(() => searchStore.activeFiltersCount > 0);

  const resetFilters = () => {
    if (hasActiveFilters.value) {
      searchStore.loading = true;

      searchStore.resetFilters();
      setQuery();

      return true;
    }
    return false;
  };

  return { hasActiveFilters, resetFilters };
};
