import { defineStore } from 'pinia';
import type { QFooter } from 'quasar';
import { ref, shallowRef } from 'vue';

export default defineStore('theme', () => {
  const pageOffsetTop = ref(0);

  const elPageSearchList = shallowRef<HTMLDivElement | null>(null);
  const elQFooter = shallowRef<InstanceType<typeof QFooter> | null>(null);
  const elListingItemFavoritesBtnBoundingsWidth = ref(0);

  const pageStyleFn = (offset: number) => {
    pageOffsetTop.value = offset;

    return {
      height: offset ? `calc(100vh - ${offset || 0}px)` : '100vh',
    };
  };

  return {
    elPageSearchList,
    pageStyleFn,
    pageOffsetTop,
    elListingItemFavoritesBtnBoundingsWidth,
    elQFooter,
  };
});
