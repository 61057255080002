<template>
  <div class="l-auction-info">
    <div class="listing-body__container--header q-mb-lg">
      <q-icon class="listing-body__icon--header" name="auction_round" />
      <span class="listing-body__text--header" v-text="t('section.auction.title')" />
    </div>

    <div
      class="text-subtitle2-bolder text-secondary q-mb-lg"
      v-text="t('section.auction.subtitle')"
    />

    <div class="text-body2 text-secondary q-pb-lg" :class="$q.screen.gt.md ? 'row' : 'column'">
      <div class="col-6">
        <div class="row text-body2 text-secondary q-pb-md">
          <span v-text="t('section.auction.currentRound')" />
          <q-space />
          <span class="text-accent" v-text="ordinal(currentRound, $i18n.locale)" />
        </div>
        <q-separator />
      </div>
    </div>

    <div class="row">
      <q-tabs
        v-for="auctionRound in auctionRounds"
        :key="auctionRound.number"
        v-model="tab"
        active-class="text-primary"
        align="justify"
        :breakpoint="0"
        class="listing__tabs text-accent"
        indicator-color="text-accent"
        no-caps
      >
        <q-tab
          :label="`${ordinal(auctionRound.number, $i18n.locale)} ${t('section.auction.round')}`"
          :name="auctionRound.number"
          :ripple="false"
        />
      </q-tabs>
    </div>
    <q-separator />

    <q-tab-panels v-model="tab" animated keep-alive>
      <q-tab-panel
        v-for="auctionRound in auctionRounds"
        :key="auctionRound.auctionId"
        :name="auctionRound.number"
        class="q-pa-none"
      >
        <div
          class="listing-body__text--label text-secondary q-pt-sm"
          :class="$q.screen.gt.md ? 'row' : 'column'"
        >
          <div class="col-6" :class="{ 'q-pr-md': $q.screen.gt.md }">
            <div class="row q-py-md">
              <span v-text="t('section.auction.startingPrice')" />
              <q-space />
              <span class="text-accent" v-text="formatPrice(auctionRound.startingPrice)" />
            </div>

            <q-separator />
          </div>

          <div class="col-6">
            <div class="row q-py-md">
              <span v-text="t('section.auction.date')" />
              <q-space />
              <span class="text-accent" v-text="auctionDateText(auctionRound.auctionDate)" />
            </div>

            <q-separator />
          </div>
        </div>
      </q-tab-panel>
    </q-tab-panels>

    <template v-if="showAuctionSiblings">
      <div class="listing-body__text--label" v-text="t('section.auction.inSeparateAuction')" />

      <LPAuctionSiblings />

      <div class="l-auction-siblings-container">
        <div v-for="(sibling, i) in auctionSiblings" :key="i" class="l-auction-sibling">
          <div class="l-auction-sibling__content">
            <q-icon :name="icons.auctionSibling" />
            <div v-text="`${formatLabel(camelCase(sibling.type))}`" />
            <div v-text="`${sibling.size}m²`" />
            <div v-text="formatPrice(sibling.startingPrice)" />
          </div>

          <q-btn
            class="l-auction-sibling__btn"
            color="white"
            no-caps
            :label="t('btn.auctionSiblings.label')"
            target="_blank"
            text-color="primary"
            :to="{
              name: 'listing',
              params: { id: sibling.listingId },
            }"
            type="a"
            unelevated
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import LPAuctionSiblings from '@/components/ListingPage/fragments/LPAuctionSiblings.vue';
import { useTheme } from '@/composables/theme';
import { useVarPool } from '@/elr/listing_page/var_pool';
import translations from '@/i18n/translations/components/listingPage.json';
import useListingStore from '@/store/modules/listing';
import { ordinal } from '@/utils/number';
import { formatLabel } from '@/utils/string';

const { formatPrice, icons } = useTheme();

const { auctionRounds, auctionSiblings } = storeToRefs(useListingStore());
const { cans } = useVarPool();
const { t, locale } = useI18n(translations);

const currentRound = computed(() => Math.max(...auctionRounds.value.map(round => round.number)));

const tab = ref(currentRound.value);

const showAuctionSiblings = computed(
  () => cans.value.includes('auction-siblings') && tab.value === currentRound.value
);

const auctionDateText = (date: string) => {
  const auctionDateLocal = dayjs(date).utc(true).tz().locale(locale.value);

  return auctionDateLocal.format('DD/MM/YYYY');
};
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.l-auction-siblings-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  background-color: c.$util-3;
  border-radius: map.get($radius-sizes, 'sm');

  .l-auction-sibling {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;

      a {
        width: 100%;
      }
    }
  }

  .l-auction-sibling__content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.6;

    .q-icon {
      width: 2rem;
      height: 2rem;
    }

    > div {
      position: relative;

      &:not(:last-child):after {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 2px;
        height: 75%;
        content: '';
        background: c.$secondary;
        transform: translate3d(0.5rem, -50%, 0);
      }
    }
  }

  .l-auction-sibling__btn {
    border: 1px solid c.$primary;
    border-radius: map.get($radius-sizes, 'md');
  }
}
</style>
