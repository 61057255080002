import type { RouteLocationNormalized } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import { useApiClient } from '@/composables/api/client';
import { useApiConfig } from '@/composables/api/config';
import { useCookies } from '@/composables/useCookies';
import i18n from '@/i18n';
import resolveFeatureGuard from '@/router/featuresRouteGuard';
import { routes } from '@/router/routes';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';
import { filtersHandler } from '@/utils/requestFiltersHandler';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const setUtmSource = (to: RouteLocationNormalized) => {
  const utmSourceKey = 'utm_source';

  const source = to.query[utmSourceKey];

  if (typeof source === 'string') {
    const { setCookieUtmSource } = useCookies();

    setCookieUtmSource(source);
  }
};

router.beforeResolve(async (to, from) => {
  const { error } = useApiClient();
  const appStore = useAppStore();
  const authStore = useAuthStore();

  const cookieKeyLocale = import.meta.env.VITE_APP_LOCALE || 'APP_LOCALE';

  const { getCookieItem, setCookieLocale } = useCookies();

  if (!appStore.config) {
    const { index: apiConfigIndex } = useApiConfig();

    const { data } = await apiConfigIndex();

    appStore.config = data.data;
  }

  const { default: localeDefault, supported: localeSupported } = appStore.config.locale;

  let locale = to.params?.locale?.toString() || getCookieItem(cookieKeyLocale) || localeDefault;

  const isLocaleSupported = !!locale && localeSupported.includes(locale);

  if (!isLocaleSupported) {
    locale = localeDefault;
  }

  i18n.global.locale.value = locale;

  setCookieLocale(locale);

  if (!isLocaleSupported) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _to = to;

    delete _to.params.locale;

    return _to;
  }

  if (authStore.user === undefined) {
    try {
      await authStore.me();
    } catch {
      //
    }
  }

  if (!authStore.user && to.meta.requiresAuth) {
    return { name: 'login' };
  }

  if (appStore.config) {
    const triggerFeatureGuard = resolveFeatureGuard(appStore.config.features, to.name?.toString());

    if (triggerFeatureGuard) {
      return {
        name: 'error',
        params: { status: 404 },
      };
    }
  }

  switch (to.name) {
    case 'error':
      if (!error.value) {
        return { name: 'index' };
      }
      break;

    case 'login':
      if (authStore.user) {
        return { name: 'index' };
      }
      break;

    case 'search':
      filtersHandler.handle(to, from);
      break;

    default:
      break;
  }

  return true;
});

router.beforeEach(to => {
  setUtmSource(to);

  return true;
});

export default router;
