<template>
  <q-layout
    :view="view"
    :class="{
      'layout-main': true,
      overlay: layoutOverlay,
    }"
  >
    <AppNavBar />

    <DialogFormStepper
      v-model="dialogAuth"
      :factory-id="dialogFactoryId"
      persistent
      :steps="formStepsAuth"
    />

    <QOverlay v-model="layoutOverlay" cursor-type="default" no-scroll opacity="0.75" :z-index="3" />

    <q-drawer
      v-model="layoutDrawer"
      :breakpoint="$q.screen.sizes.md"
      no-swipe-close
      no-swipe-open
      overlay
      persistent
      side="right"
      unelevated
      :width="$q.screen.width"
    >
      <AppNavBarDrawer />
    </q-drawer>

    <q-page-container>
      <router-view v-if="isMounted" />
    </q-page-container>

    <q-footer ref="elQFooter" class="layout-main-footer">
      <div class="layout-main-footer__head">
        <!-- use teleport -->
      </div>

      <div class="layout-main-footer__body">
        <!-- use teleport -->
      </div>

      <div class="layout-main-footer__foot">
        <!-- use teleport -->
      </div>
    </q-footer>
  </q-layout>
</template>

<script setup lang="ts">
import { QOverlay } from '@quasar/quasar-ui-qoverlay';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import AppNavBar from '@/components/AppCommon/AppNavBar.vue';
import AppNavBarDrawer from '@/components/AppCommon/AppNavBarDrawer.vue';
import DialogFormStepper from '@/components/Dialog/DialogFormStepper.vue';
import { useStepsAuth } from '@/factories/formStepsFactory/auth';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';
import useThemeStore from '@/store/modules/theme';

const route = useRoute();
const router = useRouter();

const { layoutDrawer, layoutOverlay } = storeToRefs(useAppStore());
const { dialogAuth, dialogFactoryId, routeRedirect, user } = storeToRefs(useAuthStore());
const { elQFooter } = storeToRefs(useThemeStore());

const { getFormStepsAuth } = useStepsAuth();
const formStepsAuth = getFormStepsAuth();

const isMounted = ref(false);

const view = computed(() => {
  switch (route.name) {
    case 'listing':
      return 'hhh lpr lFr';
    default:
      return 'hHh lpr fFf';
  }
});

watch(
  dialogAuth,
  v => {
    if (!v) {
      nextTick(() => {
        dialogFactoryId.value = 'auth';
      });

      if (user.value && routeRedirect.value) {
        router.push(routeRedirect.value);
      }

      routeRedirect.value = undefined;
    }
  },
  { flush: 'post' }
);

onMounted(() => {
  isMounted.value = true;
});
</script>

<style lang="scss">
@use '@/css/layouts/main/index';

.overlay {
  .q-overlay {
    background-color: $backdropOverlay !important;
  }

  .q-footer {
    z-index: 1;
  }
}
</style>
