<template>
  <div class="lp-offer">
    <q-btn :disable="isPreview" no-caps unelevated class="lp-offer__btn" @click="dialog = true">
      <div class="lp-offer__btn-container">
        <q-icon name="offer" class="lp-offer__btn-icon" />

        <div class="lp-offer__btn-container-text">
          <div class="lp-offer__btn-container-text--label" v-text="t('btn.offer.label')" />
          <div class="lp-offer__btn-container-text--tagline" v-text="t('btn.offer.tagline')" />
        </div>
      </div>
    </q-btn>

    <DialogFormStepper
      v-if="!isPreview"
      v-model="dialog"
      :factory-id="factoryId"
      persistent
      :steps="formStepsLpCtaOffer"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import DialogFormStepper from '@/components/Dialog/DialogFormStepper.vue';
import { useStepsLpOffer } from '@/factories/formStepsFactory/lpOffer';
import translations from '@/i18n/translations/components/listingPage.json';
import useListingStore from '@/store/modules/listing';

const { isPreview } = storeToRefs(useListingStore());

const { t } = useI18n(translations);
const { factoryId, getFormStepsLpOffer } = useStepsLpOffer();

const formStepsLpCtaOffer = getFormStepsLpOffer();

const dialog = ref(false);
</script>
