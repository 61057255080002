import type { SearchResultItem } from '@/types';
import { ListingCategory } from '@/types';
import type { ListingData } from '@/types/api/listing';
import CommercialHotelListing from '@/viewModels/CommercialHotelListing';
import CommercialListing from '@/viewModels/CommercialListing';
import CommercialOfficeListing from '@/viewModels/CommercialOfficeListing';
import LandListing from '@/viewModels/LandListing';
import ResidentialListing from '@/viewModels/ResidentialListing';

export default class ListingMapper {
  static fromApiResponse(
    listingData: ListingData
  ):
    | ResidentialListing
    | LandListing
    | CommercialHotelListing
    | CommercialOfficeListing
    | CommercialListing {
    const { propertyInfo } = listingData;

    const isCommercial = propertyInfo.category === ListingCategory.COMMERCIAL;
    const isLand = propertyInfo.category === ListingCategory.LAND;
    const isHotel = propertyInfo.type === 'hotel';
    const isOffice = propertyInfo.type === 'offices';

    if (isLand) return new LandListing(listingData);

    if (isCommercial) {
      if (isHotel) return new CommercialHotelListing(listingData);
      if (isOffice) return new CommercialOfficeListing(listingData);
      return new CommercialListing(listingData);
    }

    return new ResidentialListing(listingData);
  }

  static fromSearchResult(
    searchResultItem: SearchResultItem
  ):
    | ResidentialListing
    | LandListing
    | CommercialHotelListing
    | CommercialOfficeListing
    | CommercialListing {
    // Replace with the actual key value pair from searchResult if available in the feature
    const listingData: ListingData = {
      id: searchResultItem.id,
      aggregations: {
        bedrooms: searchResultItem.bedrooms,
        closedOffices: 0,
        floors: searchResultItem.floor,
        levels: searchResultItem.levels,
        parkingSpaces: searchResultItem.parkingSpaces,
        units: 0,
      },
      listingInfo: {
        agent: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
        auction: searchResultItem.auction,
        commercializationType: searchResultItem.commercializationType,
        creator: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
        exclusive: searchResultItem.exclusive,
        labels: searchResultItem.labels,
        originalPrice: {
          amount: searchResultItem?.originalPrice?.amount || 0,
          vatable: false,
          vatType: 'none',
        },
        price: searchResultItem.price,
        priceUponRequest: searchResultItem.priceUponRequest,
        priceIsReserved: searchResultItem.priceIsReserved,
        propertyCode: searchResultItem.propertyCode,
        groupPropertyParentCode: searchResultItem.groupPropertyParentCode,
        statuses: {
          published: searchResultItem.published,
          acquired: searchResultItem.acquired,
          availabilityStatus: searchResultItem.availabilityStatus,
          completionStatus: searchResultItem.completionStatus,
        },
        title: searchResultItem.title,
      },
      mediaInfo: {
        externalMedia: [],
        images: searchResultItem.images,
      },
      propertyInfo: {
        address: {
          fullAddress: searchResultItem.fullAddress,
          coordinates: searchResultItem.coordinates,
          locations: searchResultItem.locations,
        },
        building: {
          accessibilityForDisabled: false,
          buildYear: searchResultItem?.buildYear,
          cablingUtp: false,
          commonYardSize: 0,
          computerRoom: false,
          electricCarCharger: false,
          elevatorAccessToBasement: false,
          elevatorIndustrial: false,
          elevatorMaxCapacity: 0,
          elevatorsNumber: 0,
          fiberOptics: false,
          hotelBeds: searchResultItem?.hotel?.beds,
          hotelGym: false,
          hotelLicensed: false,
          hotelLicenseFnb: false,
          hotelRating: '0',
          hotelRooftop: false,
          hotelRooms: searchResultItem?.hotel?.rooms,
          hotelRoomsCondition: '',
          hotelRoomsWithPrivatePools: false,
          hotelSauna: false,
          hotelSeasonality: '',
          hotelSpa: false,
          hotelTableSeats: 0,
          loadingDock: false,
          naturalGasNetwork: false,
          numberOfIndoorPools: 0,
          numberOfPools: 0,
          securityDoor: false,
          sewerageNetwork: false,
          solarPanels: false,
          structureQuality: '',
          totalBuildingSize: 0,
          totalFloors: 0,
          totalParkingSpaces: 0,
        },
        category: searchResultItem.category,
        mainUnit: {
          airConditioning: searchResultItem.amenities.includes('Air Conditioning'),
          alarm: false,
          attic: false,
          autonomy: false,
          doubleFrontage: false,
          equipped: searchResultItem.equipped,
          fireplace: false,
          floorMaterial: [],
          furnished: searchResultItem.furnished,
          glazing: '',
          gym: false,
          interiorPool: false,
          internalElevator: false,
          petsAllowed: searchResultItem.petsAllowed,
          playroom: false,
          pool: false,
          poolSize: '',
          privateYard: 0,
          qualityLevel: '',
          saunaJacuzzi: false,
          securityDoor: false,
          sunTent: false,
          terrace: false,
          ventilationSystem: false,
          walkInCloset: false,
        },
        ownership: {
          coOwnershipRight: searchResultItem?.coOwnershipRight || null,
          coOwnershipShare: searchResultItem?.coOwnershipShare || null,
          fullOwnership: searchResultItem?.fullOwnership || false,
          owners: [],
        },
        planningZones: [],
        plot: {
          actualFrontageLength: 0,
          buildingFactor: 0,
          corner: false,
          coverageFactor: 0,
          drainage: false,
          drilling: false,
          existingBuilding: false,
          fiberOpticNetwork: false,
          flatsForLand: false,
          floorsToBuild: 0,
          frontageSize: '',
          hasBuildingPermit: false,
          landAccessibility: '',
          landlotArea: 0,
          naturalGas: false,
          plotShape: '',
          privateBeach: false,
          privateStreet: false,
          roadOrientation: '',
          seaFront: false,
          slope: '',
          view: [],
          well: false,
          withinCityZone: false,
          withinUrbanPlan: false,
        },
        runningCosts: {
          enfiaTax: 0,
          localTax: 0,
          heatingEnergy: 0,
          water: 0,
          electricity: 0,
          homeInsurance: 0,
          sharedExpenses: 0,
          cleaningService: 0,
          other: 0,
        },
        size: searchResultItem.size,
        type: searchResultItem.type,
        units: [],
      },
      suggestions: {
        similar: [],
      },
      timestamps: searchResultItem?.timestamps,
    };

    return ListingMapper.fromApiResponse(listingData);
  }
}
