import type {
  ListingAddressData,
  ListingAuction,
  ListingBreadcrumb,
  ListingFloor,
  ListingPrice,
  ListingTimestamps,
} from '@/types/api/listing';
import type {
  CommercializationType,
  ListingCategory,
  ListingCompletionStatus,
  ListingImageCollections,
  ListingType,
} from '@/types/listings';

/*= ==============================  Filters  =============================== */
export type SearchStaticFilters = {
  ctype: CommercializationType;
  category: ListingCategory;
  locations?: Array<string>;
};

export type SearchFilter<T = string> = {
  [key: string]: T | number | Array<T | number>;
};

export type SearchFilterOptions = {
  label: string;
  value: unknown;
  disable?: boolean | undefined;
  icon?: string;
};

export type SearchFilterOption<T = string> = {
  [key: string]: Array<T | number>;
};

/**
 * @author Nikos Poulias <np@devshell.com>
 * Tried to use this in search filters props but there is a limitation in imported types use
 * https://vuejs.org/guide/typescript/composition-api.html#typing-component-props
 */
export type SearchFilterProps<T = number[]> = {
  modelValue: T;
  label?: string;
  options: T;
};

/*= ============================== Sorting & Pagination   =============================== */

export enum SearchSortByParam {
  CreatedAt = '-created_at',
  Default = '-default_sort,-created_at',
  PriceAsc = 'price',
  PriceDesc = '-price',
  SizeAsc = 'size',
  SizeDesc = '-size',
}

/*= ==============================  Search results  =============================== */

type Hotel = {
  rooms?: number;
  beds?: number;
};

export type SearchResultItem = {
  id: number;
  propertyCode: string;
  groupPropertyParentCode?: string;
  acquired: boolean;
  amenities: string[];
  auction?: ListingAuction;
  availabilityStatus:
    | 'available'
    | 'acquired'
    | 'underOffer'
    | 'offerAccepted'
    | 'inCloseTenderProcess';
  bedrooms?: number;
  buildYear?: number;
  category: ListingCategory;
  coOwnershipRight?: 'usufruct' | 'bare' | 'joint';
  coOwnershipShare?: number;
  commercializationType: CommercializationType;
  coordinates: google.maps.LatLngLiteral;
  completionStatus?: ListingCompletionStatus;
  fullAddress?: ListingAddressData['fullAddress'];
  equipped: boolean;
  exclusive: boolean;
  floor: ListingFloor[] | [];
  fullOwnership?: boolean;
  furnished: boolean;
  hastener?: string;
  published: boolean;
  hotel?: Hotel;
  images: ListingImageCollections | { photos: []; floorPlans: [] };
  labels: string[];
  levels?: number;
  locations: string[];
  originalPrice?: ListingPrice;
  parkingSpaces?: number;
  petsAllowed: boolean;
  price: ListingPrice;
  priceUponRequest: boolean;
  priceIsReserved: boolean;
  renovated: boolean;
  seaFront: boolean;
  size: number;
  timestamps: ListingTimestamps;
  title: string;
  type: ListingType;
  underConstruction: boolean;
  views: string[];
};

export type SearchResultsSuggestions = {
  auction?: SearchResultItem[];
};

export type SearchResultsData = {
  listings: SearchResultItem[];
  suggestions: SearchResultsSuggestions;
  exactMatches: SearchResultItem[];
};

export type LaravelPaginator = {
  current_page: number;
  last_page: number;
  from: null | number;
  to: null | number;
  total: number;
  per_page: number;
};

export type SearchResultsMeta = {
  title: string;
  breadcrumbs: ListingBreadcrumb[];
  viewport: google.maps.LatLngBoundsLiteral | null;
} & LaravelPaginator;

export interface CoordsSearchResultItem {
  id: number;
  lat: number;
  lon: number;
  price: number;
}

export interface CoordsSearchResponse<T> {
  data: {
    listings: T;
  };
}

export type MapSearchResponse = CoordsSearchResponse<CoordsSearchResultItem[]>;

/*= ==============================  Aggregations  =============================== */
export type AggregationItem = {
  key: number;
  doc_count: number;
};
