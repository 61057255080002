<template>
  <div class="unlock-land-registration-details" @submit.prevent>
    <span v-text="t('btn.lroUnlock.prefix')" />

    <a
      class="btn-submit"
      href=""
      @click.prevent="onSubmit"
      @keyup.enter="onSubmit"
      v-text="t('btn.lroUnlock.label')"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/listingPage.json';
import useAuthStore from '@/store/modules/auth';
import useListingStore from '@/store/modules/listing';

const { isPreview, listing, listingData } = storeToRefs(useListingStore());
const { dialogAuth } = storeToRefs(useAuthStore());

const { t } = useI18n(translations);

const onSubmit = () => {
  if (isPreview.value || !listing.value || !listingData.value) return;

  dialogAuth.value = true;
};
</script>

<style lang="scss">
.unlock-land-registration-details {
  display: flex;
  gap: 0.25rem;
  align-items: baseline;
  color: $accent;

  .btn-submit {
    color: $primary;
  }
}
</style>
