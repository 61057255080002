<template>
  <div v-if="$q.screen.lt.md">
    <div class="lang-switcher-small">
      <div class="lang-switcher__content">
        <q-btn
          v-for="lang in availableLocales"
          :key="lang.value"
          flat
          unelevated
          :ripple="false"
          :label="lang.label"
          class="text-secondary text-body1-bolder lang-switcher-small-option text-uppercase"
          :class="{ 'bg-white': lang.value === $i18n.locale }"
          @click="locale = lang.value"
        />
      </div>
    </div>
  </div>

  <q-btn-dropdown
    v-else
    color="white"
    text-color="secondary"
    no-caps
    unelevated
    padding="0"
    class="lang-switcher__btn"
    size="1rem"
    :ripple="false"
    v-bind="qBtnDropdownProps"
    menu-anchor="bottom start"
    menu-self="top start"
    :content-style="{
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
    }"
    content-class="nav-bar-menu--item nav-language-switcher"
    @before-show="emit('show')"
    @before-hide="emit('hide')"
  >
    <template #label>
      <span class="text-secondary text-body2-bold text-uppercase" v-text="locale" />
    </template>

    <div class="scroll">
      <q-list>
        <q-item>
          <q-option-group v-model="locale" :options="availableLocales" type="radio">
            <template #label="opt">
              <div class="row items-center">
                <span class="text-secondary text-body2" v-text="format.capitalize($t(opt.label))" />
              </div>
            </template>
          </q-option-group>
        </q-item>
      </q-list>
    </div>
  </q-btn-dropdown>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { format, type QBtnDropdownProps } from 'quasar';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import { useCookies } from '@/composables/useCookies';
import useAppStore from '@/store/modules/app';

defineProps<{
  qBtnDropdownProps?: QBtnDropdownProps;
}>();

const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'show'): void;
}>();

const { configLocale } = storeToRefs(useAppStore());

const { locale } = useI18n();
const { setCookieLocale } = useCookies();

const route = useRoute();
const router = useRouter();

const availableLocales = computed(() =>
  configLocale.value?.supported.map(str => ({ value: str, label: str }))
);

watch(locale, v => {
  setCookieLocale(v);

  router.replace({
    name: route.name?.toString(),
    params: {
      ...route.params,
      locale: v === configLocale.value?.default ? '' : v,
    },
    query: route.query,
  });
});
</script>

<style lang="scss">
@use 'sass:map';

.lang-switcher-small {
  border-radius: map.get($radius-sizes, 'sm');

  .lang-switcher__content {
    display: flex;
    flex-wrap: wrap;
    place-items: center center;
    padding: 4px 5px;
    background: $primary-2;
    border-radius: map.get($radius-sizes, 'sm');
  }

  .lang-switcher-small-option {
    flex-grow: 1;
    width: 48px;
    border-radius: map.get($radius-sizes, 'sm');
  }
}
</style>
