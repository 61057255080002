<template>
  <div v-if="listing" :class="$q.screen.gt.xs ? 'row' : 'col'">
    <!-- Left side renovation cost container -->
    <div
      class="bg-primary renovation-cost-info row column justify-center"
      :class="[{ 'col-8': $q.screen.gt.xs }, $q.screen.lt.sm ? 'q-px-lg q-pt-lg' : 'q-pa-lg']"
    >
      <div class="row items-center text-white q-pb-lg">
        <q-icon name="renovation" size="55px" color="white" class="q-mr-md" />

        <div class="text-white text-h4">
          <div v-text="t('section.renovation.title.0')" />
          <div v-text="t('section.renovation.title.1')" />
        </div>
      </div>

      <div class="text-white text-body2 q-pb-lg">
        <div class="row no-wrap">
          <q-icon name="success" class="q-mr-sm" size="22px" color="primary" />
          <span v-text="t('section.renovation.taglines.0', { company: configApp.company.name })" />
        </div>
      </div>

      <div class="text-white text-body2 q-pb-lg">
        <div class="row no-wrap">
          <q-icon name="success" class="q-mr-sm" size="22px" color="primary" />
          <span v-text="t('section.renovation.taglines.1')" />
        </div>
      </div>

      <!-- Desktop button -->
      <q-btn
        v-if="$q.screen.gt.xs"
        unelevated
        rounded
        no-caps
        color="white"
        text-color="primary"
        type="submit"
        class="view-renovation-btn"
        @click="dialog = true"
      >
        <div class="row content-center items-center no-wrap">
          <q-icon name="export" size="20px" />
          <div class="text-body2-bold q-pa-xs" v-text="t('btn.renovation.openModal.label')" />
        </div>
      </q-btn>
    </div>
    <!-- Right side renovation cost container -->
    <div
      class="renovation-cost-bubbles"
      :class="[
        {
          'col-4': $q.screen.gt.xs,
          'renovation-cost-bg': $q.screen.gt.xs || (!$q.screen.gt.xs && !$q.screen.lt.sm),
          'bg-primary': !$q.screen.gt.xs && $q.screen.lt.sm,
        },
        $q.screen.lt.sm ? 'q-px-lg q-pb-lg' : 'q-pa-lg',
      ]"
    >
      <div
        v-if="renovationCostsSummary"
        :class="
          !$q.screen.gt.xs
            ? 'row items-end justify-center no-wrap full-height'
            : 'column justify-center items-center full-height'
        "
      >
        <div
          class="text-primary renovation-cost-bubble column justify-center items-center"
          :class="!$q.screen.gt.xs ? 'q-mr-lg' : 'q-mb-sm'"
        >
          <div class="text-subtitle2" v-text="formatPrice(renovationCostsSummary.basic)" />
          <div class="text-secondary text-subtitle2">Basic</div>
        </div>
        <div>
          <div class="text-primary renovation-cost-bubble column justify-center items-center">
            <q-icon name="diamond" color="primary" size="22px" />
            <div
              class="text-subtitle2 q-pt-sm"
              v-text="formatPrice(renovationCostsSummary.premium)"
            />
            <div class="text-secondary text-subtitle2">Premium</div>
          </div>
        </div>
      </div>
      <!-- Mobile button -->
      <div v-if="!$q.screen.gt.xs" class="row justify-center q-mt-lg">
        <q-btn
          unelevated
          rounded
          no-caps
          color="white"
          text-color="primary"
          :class="{ 'full-width': $q.screen.xs }"
          @click="dialog = true"
        >
          <div class="row content-center items-center no-wrap">
            <q-icon name="export" size="20px" />
            <div class="text-body2-bold q-pa-xs" v-text="t('btn.renovation.openModal.label')" />
          </div>
        </q-btn>
      </div>
    </div>
    <!-- Mobile image -->
    <div v-if="!$q.screen.gt.xs && $q.screen.lt.sm" class="renovation-cost-bg-s" />

    <q-dialog v-if="renovationCostsSummary" v-model="dialog" maximized>
      <LPModalRenovationCost
        :listing-id="listing.id"
        :renovation-costs-summary="renovationCostsSummary"
        @close="dialog = false"
      />
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import LPModalRenovationCost from '@/components/ListingPage/Modal/LPModalRenovationCost.vue';
import { useApiListing } from '@/composables/api/listing';
import { useTheme } from '@/composables/theme';
import configApp from '@/config/app.json';
import translations from '@/i18n/translations/components/listingPage.json';
import useListingStore from '@/store/modules/listing';
import type { ListingRenovationCostTypes } from '@/types/api/listing';

const { showRenovationCostSummary } = useApiListing();

const { t } = useI18n(translations);

const { formatPrice } = useTheme();

const { listing } = storeToRefs(useListingStore());

const dialog = ref(false);
const renovationCostsSummary = ref<ListingRenovationCostTypes>();

const getListingRenovationCostsSummary = () => {
  if (!listing.value) return;

  showRenovationCostSummary(listing.value.id)
    .then(({ data }) => {
      renovationCostsSummary.value = data.data;
    })
    .catch(e => {
      console.error(e);
    });
};

getListingRenovationCostsSummary();
</script>

<style lang="scss" scoped>
@use 'sass:map';

.renovation-cost-info {
  border-radius: map.get($radius-sizes, md) 0 0 map.get($radius-sizes, md);
  @media (width <= 599px) {
    border-radius: map.get($radius-sizes, md) map.get($radius-sizes, md) 0 0;
  }
}

.renovation-cost-bubbles {
  border-radius: 0 map.get($radius-sizes, md) map.get($radius-sizes, md) 0;
  @media (width <= 599px) {
    border-radius: 0;
  }
}

.renovation-cost-bg {
  background: $primary url('/images/bg-renovation.png') no-repeat;
  background-position-x: left;
  background-size: cover;
  @media (width >= 1000px) and (width <= 1199px), (width <= 430px) {
    background-position-x: 35px;
  }
}

.renovation-cost-bg-s {
  height: 180px;
  background: $primary url('/images/bg-renovation-small.png') no-repeat;
  background-size: cover;
  border-radius: 0 0 10px 10px;
}

.renovation-cost-bubble {
  width: 115px;
  height: 115px;
  background-color: $menu-background;
  border: 6px solid $primary-2;
  border-radius: 30%;
}

.view-renovation-btn {
  max-width: 80%;
  @media (width >= 700px) and (width <= 1199px) {
    max-width: 65%;
  }
}
</style>
