<template>
  <div class="sp-filter-category">
    <q-btn-dropdown
      color="white"
      no-caps
      unelevated
      padding="0"
      :ripple="false"
      text-color="secondary"
      v-bind="qBtnDropdownProps"
      size="1rem"
      menu-anchor="bottom start"
      menu-self="top start"
      :content-style="{ borderTopLeftRadius: '0', borderTopRightRadius: '0' }"
      content-class="nav-bar-menu--item filter-category"
      @before-show="emit('show')"
      @before-hide="emit('hide')"
    >
      <template #label>
        <span class="sp-f-nav-text" v-text="text" />
      </template>
      <div class="scroll">
        <q-list>
          <q-item>
            <q-option-group
              :model-value="searchStore.staticFilters.category"
              :options="categories"
              type="radio"
              @update:model-value="updateCategory($event)"
            >
              <template #label="opt">
                <div class="row items-center">
                  <span class="text-secondary text-body2">{{ opt.label }}</span>
                </div>
              </template>
            </q-option-group>
          </q-item>
        </q-list>
      </div>
    </q-btn-dropdown>
  </div>
</template>

<script lang="ts" setup>
import _get from 'lodash/get';
import type { QBtnDropdownProps } from 'quasar';
import { computed } from 'vue';

import useSearchStore from '@/store/modules/search';
import { ListingCategory } from '@/types';
import { formatLabel } from '@/utils/string';

const searchStore = useSearchStore();

defineProps<{
  qBtnDropdownProps?: QBtnDropdownProps;
}>();

const categories = computed(() => {
  const categoriesKeys = Object.keys(ListingCategory);

  return categoriesKeys.map(key => ({
    label:
      _get(ListingCategory, key) === 'all'
        ? formatLabel('allCategories', true)
        : formatLabel(_get(ListingCategory, key)),
    value: _get(ListingCategory, key),
    icon: _get(ListingCategory, key),
  }));
});

const text = computed(
  () =>
    categories.value.find(category => category.value === searchStore.staticFilters.category)?.label
);

const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'show'): void;
}>();

const updateCategory = (category: ListingCategory) => {
  searchStore.previousStaticFilters = { ...searchStore.staticFilters };

  searchStore.$patch({
    staticFilters: { ...searchStore.staticFilters, category },
    page: 1,
  });
};
</script>
