<template>
  <a :href="href" class="company-phone-number" v-text="number" />
</template>

<script setup lang="ts">
import { computed } from 'vue';

import configApp from '@/config/app.json';

const { code, number } = configApp.company.phone;

const href = computed(() => {
  const prefix = 'tel:';

  const hrefLocal = number.startsWith(code) ? `${number}` : `${code}${number}`;

  return `${prefix}${hrefLocal}`.replaceAll(' ', '');
});
</script>

<style lang="scss">
.company-phone-number {
  color: $primary;
  text-decoration: none;
}
</style>
