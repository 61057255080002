<template>
  <div>
    <div
      class="text-subtitle2-bolder text-secondary q-mb-md"
      v-text="t('section.financial.runningCosts.title')"
    />

    <div class="running-costs__content">
      <div class="running-cost-chart">
        <DoughnutChart :options="options" :data="data" chart-id="d-chart" />
      </div>

      <div class="running-costs__info">
        <div v-for="cost in runningCosts" :key="cost.color" class="running-costs__info-container">
          <div class="running-cost-legend" :style="`background-color: ${cost.color}`" />
          <div class="text-body2 text-secondary col" v-text="cost.label" />

          <div class="text-body2 text-accent" v-text="formatPrice(cost.value)" />
        </div>

        <div class="row wrap content-center q-my-sm">
          <q-space />

          <div
            class="text-body2-bold text-secondary"
            v-text="`${t('section.financial.runningCosts.total')} ${formatPrice(total)}`"
          />
        </div>
      </div>
    </div>

    <div class="q-mt-lg3">
      <div class="text-body2 text-accent" v-text="t('section.financial.runningCosts.disclaimer')" />

      <q-separator class="q-mt-xs" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  type ChartData,
  type ChartOptions,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { Doughnut as DoughnutChart } from 'vue-chartjs';
import { useI18n } from 'vue-i18n';

import { useTheme } from '@/composables/theme';
import translations from '@/i18n/translations/components/listingPage.json';
import useListingStore from '@/store/modules/listing';
import type { ListingRunningCosts } from '@/types/api/listing';

const { listing } = storeToRefs(useListingStore());
const { formatPrice } = useTheme();

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

type RunningCost = { label: string; color: string; value: number };

const { t } = useI18n(translations);

const colorMap: { [cost in keyof ListingRunningCosts]: string } = {
  cleaningService: '#F0EDFF',
  electricity: '#302479',
  enfiaTax: '#5FB2FF',
  heatingEnergy: '#C0B1FC',
  homeInsurance: '#F78F9B',
  localTax: '#FFE069',
  other: '#E73F53',
  sharedExpenses: '#613CF8',
  water: '#3DDCAD',
};

const options: ChartOptions<'doughnut'> = {
  responsive: true,
  maintainAspectRatio: false,
};

const runningCosts = ref<RunningCost[]>();

// Running costs divided by 12 Months
const total = computed(
  () => Object.values(listing.value?.runningCosts || {}).reduce((acc, curr) => acc + curr, 0) / 12
);

const data = computed<ChartData<'doughnut'>>(() => ({
  datasets: [
    {
      backgroundColor: runningCosts.value?.map(el => el.color) || [],
      data: runningCosts.value?.map(el => el.value) || [],
    },
  ],
}));

const transformExpensesData = () => {
  if (!listing.value?.runningCosts) return [];

  const runningExpenses: RunningCost[] = [];

  Object.keys(listing.value.runningCosts).forEach((key: string) => {
    const color = colorMap[key as keyof ListingRunningCosts];

    const value =
      listing.value?.runningCosts && listing.value.runningCosts[key as keyof ListingRunningCosts];

    if (color && value) {
      runningExpenses.push({
        color,
        label: t(`section.financial.runningCosts.labels.${key}`),
        value: value / 12, // cost divided by 12 Months
      });
    }
  });

  return runningExpenses;
};

onMounted(() => {
  runningCosts.value = transformExpensesData();
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

.running-costs__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .running-costs__info {
    display: flex;
    flex-direction: column;
    gap: 0.375rem 0;
    min-width: 50%;

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }
}

.running-cost-chart {
  flex-grow: 1;
  width: 50%;

  canvas {
    width: 100%;
    height: 250px;

    @media (max-width: $breakpoint-xs) {
      height: 200px;
      margin-bottom: 1rem;
    }
  }
}

.running-cost-legend {
  min-width: 10%;
  height: 8px;
  border-radius: map.get($radius-sizes, 'xs');
}

.running-costs__info-container {
  display: flex;
  gap: 0 0.75rem;
  align-items: center;
  width: 100%;
}
</style>
