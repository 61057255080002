import { createI18n } from 'vue-i18n';

import configLocalization from '@/config/localization.json';

const modules: Record<string, { default: any }> = import.meta.glob('@/i18n/translations/*.json', {
  eager: true,
});

const messages: Record<string, Record<string, string>> = {};

Object.entries(modules).forEach(([key, value]) => {
  const filename = key.lastIndexOf('/');

  const locale = key.substring(filename + 1, key.lastIndexOf('.'));

  messages[locale] = value.default;
});

const i18n = createI18n({
  dateTimeFormats: configLocalization.dateTimeFormats,
  globalInjection: true,
  messages,
  numberFormats: {
    el: {
      currency: {
        currency: 'EUR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency',
      },
    },
    en: {
      currency: {
        currency: 'EUR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency',
      },
    },
  },
  legacy: false,
});

export default i18n;
