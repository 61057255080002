<template>
  <q-checkbox v-model="searchStore.searchAsIMove" size="2rem" color="secondary">
    <template #default>
      <span class="text-caption-bolder text-secondary"
        >{{ format.capitalize(t('searchAsIMoveTheMap')) }}
      </span>
    </template>
  </q-checkbox>
</template>

<script lang="ts" setup>
import { format } from 'quasar';
import { useI18n } from 'vue-i18n';

import useSearchStore from '@/store/modules/search';

const searchStore = useSearchStore();
const { t } = useI18n();
</script>

<style lang="scss" scoped>
@use 'sass:map';

.q-checkbox {
  padding: 2px 16px;
  background: #fff;
  border-radius: map.get($radius-sizes, lg);
  box-shadow: 0 4px 8px rgb(124 151 218 / 25%);
}
</style>
