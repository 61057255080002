import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import i18n from '@/i18n';
import { type SearchFilterOption } from '@/types';
import type { ConfigPublicHoliday, IndexConfigResponse } from '@/types/api/config';
import { getDeepKey } from '@/utils/object';

export default defineStore(
  'app',
  () => {
    const config = ref<IndexConfigResponse['data']>();

    const filters = ref<Record<'primary' | 'secondary', SearchFilterOption>>({
      primary: {},
      secondary: {},
    });

    const layoutDrawer = ref(false);
    const layoutOverlay = ref(false);

    const allFilterKeys = computed(() =>
      Object.keys({ ...filters.value.primary, ...filters.value.secondary })
    );
    const allFilters = computed(() => ({ ...filters.value.primary, ...filters.value.secondary }));
    const primaryFilterKeys = computed(() => Object.keys(filters.value.primary));
    const secondaryFilterKeys = computed(() => Object.keys(filters.value.secondary));

    const getFilter = (key: string) => getDeepKey(filters.value, key);

    const commercializationTypes = computed(() => {
      if (!config.value?.commercialization_types) return [];

      return config.value.commercialization_types.map(el => ({
        label: el.slug,
        value: el.slug,
      }));
    });

    const filterIsPrimary = (key: string) => Object.keys(filters.value.primary).includes(key);

    const publicHolidaysDates = computed(() =>
      config.value?.public_holidays.map((holiday: ConfigPublicHoliday) => holiday.date)
    );

    const configCountry = computed(() => config.value?.country);

    const configLocale = computed(() => config.value?.locale);

    const currentLocale = computed(
      () =>
        (configLocale.value &&
          i18n.global.locale.value !== configLocale.value.default &&
          i18n.global.locale.value) ||
        ''
    );

    return {
      allFilterKeys,
      allFilters,
      commercializationTypes,
      config,
      configCountry,
      configLocale,
      currentLocale,
      filterIsPrimary,
      filters,
      getFilter,
      layoutDrawer,
      layoutOverlay,
      primaryFilterKeys,
      publicHolidaysDates,
      secondaryFilterKeys,
    };
  },
  {
    share: {
      enable: true,
      initialize: false,
      omit: ['filters', 'layoutDrawer', 'layoutOverlay'],
    },
  }
);
