import type { ConfigFeature } from '@/types/api/config';

type ProtectedFeatures = {
  toName: string;
  featureDependency: string[];
};

const featuresToProtect: ProtectedFeatures[] = [
  {
    toName: 'list-property',
    featureDependency: ['listingSubmission'],
  },
  { toName: 'cp-offers', featureDependency: ['offerSubmission'] },
  { toName: 'cp-visits', featureDependency: ['openDays', 'viewings'] },
];

/**
 * Based on the feature config per client, generate a guard trigger for specific features/urls
 */
const resolveFeatureGuard = (features?: ConfigFeature[], toName = '') => {
  if (!toName) return false;

  if (!features) {
    console.warn('Resolve feature guard: features not set');
  }

  let guardedFeatures: string[] = [];

  // Array of features that have dependency to another feature and
  // all need to be disabled in order for the guard to take effect
  const coDependantFeatures: string[][] = [['viewings', 'openDays']];

  features?.forEach(item => {
    // Listing submission guard
    if (
      item.feature === 'listingSubmission' &&
      !item.enabled &&
      item.options?.visible_in?.includes('listing') &&
      item.options?.visible_in?.includes('search')
    ) {
      guardedFeatures.push(item.feature);
    }

    // Visits/Offers for cPanel guard
    if (
      ['viewings', 'openDays', 'offerSubmission'].includes(item.feature) &&
      !item.enabled &&
      item.options?.visible_in?.includes('cPanel')
    ) {
      guardedFeatures.push(item.feature);
    }
  });

  // For co-dependant features we need to ensure that all features have been added to the guarded ones.
  coDependantFeatures.forEach(coDependantFeature => {
    const allCoDependantFeatureIncluded = coDependantFeature.every(v =>
      guardedFeatures.includes(v)
    );

    if (!allCoDependantFeatureIncluded) {
      guardedFeatures = guardedFeatures.filter(item => !coDependantFeature.includes(item));
    }
  });

  return !!featuresToProtect.find(
    item => item.toName === toName && item.featureDependency.every(v => guardedFeatures.includes(v))
  );
};

export default resolveFeatureGuard;
