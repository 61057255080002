<template>
  <q-breadcrumbs gutter="none" class="l-breadcrumbs">
    <template #separator>
      <q-icon :size="separatorIconSize" :name="separatorIconName" :color="separatorIconColor" />
    </template>

    <q-breadcrumbs-el
      v-for="(breadcrumb, i) in breadcrumbs"
      :key="i"
      :label="formatLabel(breadcrumb.label, i)"
      :to="listingLocalizedBreadcrumbs(breadcrumb.uri)"
      target="_blank"
    />
  </q-breadcrumbs>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useAppStore from '@/store/modules/app';
import useThemeStore from '@/store/modules/theme';
import type { ListingBreadcrumb } from '@/types/api/listing';
import { capitalize, capitalizeSentence } from '@/utils/string';

interface Props {
  breadcrumbs: ListingBreadcrumb[];
  separatorIconName?: string;
  separatorIconSize?: string;
  separatorIconColor?: string;
}
withDefaults(defineProps<Props>(), {
  breadcrumbs: () => [],
  separatorIconName: 'chevron_right',
  separatorIconSize: '0.5rem',
  separatorIconColor: 'primary',
});

const { locale } = useI18n();
const { config } = storeToRefs(useAppStore());
const { elListingItemFavoritesBtnBoundingsWidth } = storeToRefs(useThemeStore());

const elListingItemFavoritesBtnWidth = computed(
  () => `${elListingItemFavoritesBtnBoundingsWidth.value}px`
);

const listingLocalizedBreadcrumbs = (path: string) => {
  let uri = path;

  const defaultLocale = config.value?.locale.default;

  const prefix = locale.value === defaultLocale ? '' : `/${locale.value}`;
  if (uri.charAt(0) === '/') uri = path.slice(1);

  return `${prefix}/${uri}`;
};

const formatLabel = (label: string, i: number) => {
  if (i === 0) return capitalize(label);

  return capitalizeSentence(label);
};
</script>

<style lang="scss">
.l-breadcrumbs {
  width: calc(100% - v-bind('elListingItemFavoritesBtnWidth'));

  > div {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: flex-start;
  }

  .q-breadcrumbs__el {
    font-size: 0.75rem;
    font-weight: 600;
    color: $accent;
  }

  @media (max-width: $breakpoint-xs) {
    display: inline-flex;
    padding-right: 0.5rem;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    > div {
      flex: none;
    }
  }
}

.l-breadcrumbs::-webkit-scrollbar {
  display: none;
}
</style>
