<template>
  <q-btn
    :disable="loadingState && $q.screen.lt.sm"
    :icon="icon"
    :label="btnText"
    unelevated
    no-caps
    :ripple="false"
    class="toggle-map-btn"
    @click="toggleShowOnlyMap"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { format, Screen } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useSearchStore from '@/store/modules/search';

const { loadingState, showOnlyMap } = storeToRefs(useSearchStore());

const { t } = useI18n();

const btnText = computed(() => {
  return showOnlyMap.value ? format.capitalize(t('showList')) : format.capitalize(t('showOnlyMap'));
});

const toggleShowOnlyMap = () => {
  showOnlyMap.value = !showOnlyMap.value;
};

const icon = computed(() => {
  const { md } = Screen.lt;
  if (showOnlyMap.value) {
    return md ? 'list' : 'keyboard_arrow_right';
  }
  return md ? 'map' : 'keyboard_arrow_left';
});
</script>

<style lang="scss">
@use 'sass:map';

.toggle-map-btn {
  width: 100%;
  max-width: 380px;
  border-radius: map.get($radius-sizes, xl);

  .q-icon {
    svg {
      width: 0.625rem;
      height: 0.625rem;
      @media (max-width: $breakpoint-sm) {
        width: 1.125rem;
        height: 1.125rem;
      }
    }

    &.on-left {
      margin-right: 0.25rem;
    }
  }
}
</style>
