<template>
  <q-page>
    <LPSkeleton v-if="loading" key="lp-skeleton" />

    <div v-else-if="listing" v-scroll="onScroll">
      <ExpressionLanguageRenderer
        v-for="(c, i) in template"
        :key="i"
        v-bind="c"
        :var-pool="varPool"
      />
    </div>

    <q-page-sticky position="bottom-right" :offset="scrollToTopBtnOffset" style="z-index: 9999">
      <Transition name="fade">
        <q-btn
          v-show="scrollToTopBtnVisible"
          class="listing-scroll-to-top-btn"
          icon="keyboard_arrow_up"
          unelevated
          @click="onClickScrollToTop"
        />
      </Transition>
    </q-page-sticky>
  </q-page>
</template>

<script setup lang="ts">
import { useTitle } from '@vueuse/core';
import camelCase from 'lodash/camelCase';
import { storeToRefs } from 'pinia';
import { useQuasar } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import ExpressionLanguageRenderer from '@/components/ExpressionLanguageRenderer.vue';
import LPSkeleton from '@/components/ListingPage/LPSkeleton.vue';
import { useBoot } from '@/composables/boot';
import configApp from '@/config/app.json';
import template from '@/elr/listing_page/template.json';
import { useVarPool } from '@/elr/listing_page/var_pool';
import translations from '@/i18n/translations/components/listingPage.json';
import useListingStore from '@/store/modules/listing';
import { toThousands } from '@/utils/number';
import { capitalizeSentence, formatLabel } from '@/utils/string';

const listingStore = useListingStore();
const { listing, loading } = storeToRefs(listingStore);
const { t } = useI18n(translations);

const { bootListing } = useBoot();

const { screen } = useQuasar();

const varPool = {
  ...useVarPool(),
  ...storeToRefs(listingStore),
};

const scrollToTopBtnVisible = ref(false);
const scrollToTopBtnVisibleThreshold = 800;

const scrollToTopBtnOffset = computed(() => {
  let x = 48;
  let y = 30;

  switch (screen.name) {
    case 'md':
      x = 24;
      break;
    case 'sm':
    case 'xs':
      x = 16;
      y = 3;
      break;
    default:
      break;
  }

  return [x, y];
});

const onScroll = (p: number) => {
  scrollToTopBtnVisible.value = p >= scrollToTopBtnVisibleThreshold;
};

const listingPageTitle = computed(() => {
  if (!listing.value) return '';

  const tKeyPrefix = 'meta.title';

  const size = `${toThousands(listing.value.size)} ${t(`${tKeyPrefix}.sqm`)}`;
  const { locations } = listing.value.address;

  const locationToShow = locations[locations.length - 1]
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substring(1))
    .join(' ');

  return `${t(`${tKeyPrefix}.${listing.value.commercializationType}`)} ${formatLabel(
    camelCase(listing.value.type)
  )} - ${size} - ${capitalizeSentence(locationToShow)} | ${configApp.company.name}`;
});

const onClickScrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

useTitle(listingPageTitle);

bootListing();
</script>
