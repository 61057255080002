<template>
  <SPLocationsMobile
    v-if="$q.screen.lt.md"
    :model-value="showMobileSearch"
    :external-requested-locations="initialLoadLocations"
    @update:model-value="updateShowMobileSearch"
  />
  <SPLocationsDesktop
    v-else
    :external-requested-locations="initialLoadLocations"
    @show="emit('show')"
    @hide="emit('hide')"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import SPLocationsDesktop from '@/components/SearchPage/Locations/SPLocationsDesktop.vue';
import SPLocationsMobile from '@/components/SearchPage/Locations/SPLocationsMobile.vue';
import { useApiSearch } from '@/composables/api/search';
import useAppStore from '@/store/modules/app';
import useSearchStore from '@/store/modules/search';

interface Location {
  full_name: string;
  name: string;
  slug: string;
  fragments: string[];
  ctypes: string[];
}

const { indexLocations } = useApiSearch();

const { locale } = useI18n();
const { layoutDrawer } = storeToRefs(useAppStore());
const searchStore = useSearchStore();

const showMobileSearch = ref(false);

const updateShowMobileSearch = (newValue: boolean) => {
  showMobileSearch.value = newValue;
};

const initialLoadLocations = ref<Location[]>([]);

const emit = defineEmits<{
  (event: 'show'): void;
  (event: 'hide'): void;
}>();

const initializeLocations = () => {
  if (searchStore.staticFilters.locations) {
    Promise.all(
      searchStore.staticFilters.locations.map((loc: string) =>
        indexLocations({ filters: { name: loc }, type: 'exact' }).then(res => {
          return res.data.data.filter(item => item.slug === loc);
        })
      )
    ).then(responses => {
      const allLocations: Location[] = responses
        .flat(1)
        .filter(obj => searchStore.staticFilters.locations?.includes(obj.slug));

      initialLoadLocations.value = allLocations.map(location => reactive(location as Location));
    });
  }
};

onMounted(() => {
  initializeLocations();
});

watch(layoutDrawer, v => {
  if (v) {
    updateShowMobileSearch(!v);
  }
});

// Required to trigger re-fetching titles and re-creating the SEO title of the page
watch(locale, () => {
  initializeLocations();
});

// Required to trigger reset of selected locations in search when we have geo_box
watch(
  () => searchStore.filters,
  () => {
    if (searchStore.filtersHave('geo_box') && searchStore.staticFiltersHave('locations')) {
      initialLoadLocations.value = [];
    }
  }
);
</script>
