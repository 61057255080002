import dayjs from 'dayjs';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { useApiListing } from '@/composables/api/listing';
import type { Attribute } from '@/composables/attributesRenderer';
import { useFeatureConfig } from '@/composables/featureConfig';
import { useTheme } from '@/composables/theme';
import configApp from '@/config/app.json';
import ListingMapper from '@/mappers/listingMapper';
import useAuthStore from '@/store/modules/auth';
import type { ListingConfig, ListingData, ListingMetaData } from '@/types/api/listing';

export default defineStore('listing', () => {
  const { show, showIdentificationDetails, showPreview } = useApiListing();

  const listingData = ref<ListingData>();
  const listingConfig = ref<ListingConfig>();
  const listingMetaData = ref<ListingMetaData>();

  const loading = ref(false);

  const { locale } = useI18n();
  const route = useRoute();
  const { getComponentConfig, getImgRibbonText } = useTheme();
  const { featureConfig } = useFeatureConfig();
  const { user } = storeToRefs(useAuthStore());

  const { ListingPage, CtaWidget } = getComponentConfig();

  const listingId = computed(() => route.params.id.toString());

  const loadCss = () => {
    import(`@/css/page/listing/${ListingPage.cssUrl}/index.scss`)
      .then(module => {
        const style = document.createElement('style');
        style.innerHTML = module.default;
        document.head.appendChild(style);
      })
      .catch(e => {
        console.error('Error loading CSS:', e);
      });
  };

  const isPreview = computed(() => route.name === 'listing-preview');
  const listing = computed(() =>
    listingData.value ? ListingMapper.fromApiResponse(listingData.value) : null
  );

  const hasLabel = (label: string) => !!listing.value?.listingInfo.labels.includes(label);

  const loadListing = () => {
    if (Array.isArray(listingId) || !listingId.value.length) return;

    loading.value = true;

    const fetch = route.name === 'listing-preview' ? showPreview : show;

    fetch(listingId.value)
      .then(response => {
        const { data, config, meta } = response.data;
        listingData.value = data;
        listingConfig.value = config;
        listingMetaData.value = meta;
        loading.value = false;
      })
      .catch(err => {
        console.error(err);
      });
  };

  const hydrateLro = () => {
    if (
      !(
        featureConfig.value.listing.identificationDetails &&
        listingMetaData.value?.hasIdentificationDetails
      ) ||
      !listing.value ||
      isPreview.value
    )
      return false;

    return watch(user, v => {
      if (!v || !listing.value || !listingData.value || listingData.value.propertyInfo.lro) return;

      showIdentificationDetails(listing.value.id)
        .then(response => {
          const { data } = response.data;
          if (data && listingData.value) {
            listingData.value.propertyInfo.lro = data;
          }
        })
        .catch(e => {
          console.error(e);
        });
    });
  };

  const auctionRounds = computed(() => listing.value?.auction?.rounds || []);

  const auctionDate = computed(() => {
    if (!listing.value?.auction) return '';

    const auctionDateLocal = dayjs(listing.value.auction.auctionDate())
      .utc(true)
      .tz()
      .locale(locale.value);

    return auctionDateLocal.format(CtaWidget.auctionRound.dateFormat);
  });

  const newlyListedVisible = computed(() =>
    listing.value?.isNewlyCreated(configApp.newListingThresholdDays)
  );

  const agent = computed(() => listing.value?.agent);

  const amenities = computed<Attribute[]>(() => {
    if (!listing.value || !listingData.value) return [];

    return (
      listingData.value.propertyInfo.units.length > 1
        ? listing.value.amenities.filter(item => !['floors', 'levels'].includes(item.name))
        : listing.value.amenities
    ).map(a => ({ icon: a.icon, key: a.name, value: a.label }));
  });
  const auctionSiblings = computed(() => listingData.value?.listingInfo.auction?.siblings || []);
  const disclaimers = computed(() => listingData.value?.listingInfo.disclaimer || []);
  const documents = computed(() => listingData.value?.mediaInfo.documents || []);
  const images = computed(() => listingData.value?.mediaInfo.images);
  const lro = computed(() => listingData.value?.propertyInfo.lro);
  const planningZones = computed(() => listingData.value?.propertyInfo.planningZones || []);
  const ribbonText = computed(() =>
    listing.value
      ? getImgRibbonText(listing.value?.availabilityStatus, listing.value?.commercializationType)
      : ''
  );

  return {
    hasLabel,
    isPreview,
    listing,
    loadCss,
    loadListing,
    loading,
    listingData,
    listingConfig,
    listingMetaData,

    //
    agent,
    amenities,
    auctionDate,
    auctionRounds,
    auctionSiblings,
    disclaimers,
    documents,
    hydrateLro,
    images,
    lro,
    newlyListedVisible,
    planningZones,
    ribbonText,
  };
});
