<template>
  <q-form class="f-form lp-form-auction-bid" :form-id="btnPropsSubmit.form">
    <div class="lp-form-auction-bid__container">
      <div
        v-if="!listing?.auction?.dateToBeAnnounced"
        class="f-form-auction-bid__container-countdown"
      >
        <div
          class="lp-form-auction-bid__container--text"
          v-text="t('LPAuctionBid.extra.timeRemaining')"
        />

        <vue-countdown
          v-slot="timer"
          :time="auctionBidUnavailable ? 0 : auctionTime"
          :transform="transformSlotProps"
          class="v-countdown--container"
        >
          <div v-for="key in countdownKeys" :key="key" class="v-countdown--content">
            <div class="v-countdown--content-number" v-text="timer[key]" />
            <div class="v-countdown--content-text" v-text="t(`LPAuctionBid.extra.${key}`)" />
          </div>
        </vue-countdown>
      </div>

      <template v-if="!auctionBidUnavailable">
        <Teleport defer :disabled="!isDialog" :to="teleportTarget">
          <q-btn v-bind="btnPropsSubmit" />
        </Teleport>
      </template>
    </div>
  </q-form>
</template>

<script setup lang="ts">
import VueCountdown from '@chenfengyuan/vue-countdown';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import configApp from '@/config/app.json';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import useListingStore from '@/store/modules/listing';
import type { FormId } from '@/types/formStepsFactory';
import { dateTimeDiff } from '@/utils/time';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
}>();

const { t } = useI18n(tFormSteps);

const { listing } = storeToRefs(useListingStore());

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const countdownKeys = ['days', 'hours', 'minutes'];

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl q-mt-lg2',
  color: 'primary',
  form: `f-${props.formId}`,
  href: configApp.company.links.auctionBidInformation,
  label: t('LPAuctionBid.btn.submit'),
  noCaps: true,
  padding: '0.8rem',
  rel: 'noopener noreferrer',
  target: '_blank',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const auctionTime = computed(() => {
  const auctionDate = dayjs(listing.value?.auction?.auctionDate()).utc(true).tz();
  if (!auctionDate) return 0;

  const timeDiff = dateTimeDiff(auctionDate, dayjs());

  return Math.max(timeDiff, 0);
});

const transformSlotProps = (slotProps: Record<string, number>) => {
  const formattedProps: Record<string, string> = {};
  Object.entries(slotProps).forEach(([key, value]) => {
    formattedProps[key] = value < 10 ? `0${value}` : `${value}`;
  });

  return formattedProps;
};

const auctionBidUnavailable = computed(
  () =>
    listing.value?.auction?.getWorkingDaysDiff() === 0 && !listing.value?.auction?.dateToBeAnnounced
);
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.lp-auction-bid__form {
  text-align: center;
  background: c.$info-1;
  border: 1px solid c.$primary;
  border-radius: map.get($radius-sizes, 'md');

  .q-avatar {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
    background: white;
  }

  .lp-form-auction-bid__container--text {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: c.$accent;
  }

  .stepper-form__header--title {
    margin-bottom: 1.5rem;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    color: c.$primary;
  }

  .stepper-form--container {
    padding-top: 0;
  }

  .stepper-form__header--subtitle {
    font-size: 1.125rem;
    font-weight: 800;
    color: black;
  }
}

.v-countdown--container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  color: black;
  background: white;
  border-radius: 0.5rem;
}

.v-countdown--content {
  display: flex;
  flex-direction: column;
}

.v-countdown--content-number {
  font-size: 2rem;
  font-weight: 800;
  line-height: 140%;
}

.v-countdown--content-text {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 140%;
  color: c.$accent;
}
</style>
