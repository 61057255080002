import { Screen } from 'quasar';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useSearchPage } from '@/composables/searchPage';
import { useTheme } from '@/composables/theme';
import useAuthStore from '@/store/modules/auth';
import useSearchStore from '@/store/modules/search';
import useThemeStore from '@/store/modules/theme';

export const useVarPool = () => {
  const authStore = useAuthStore();
  const searchPage = useSearchPage();
  const themeStore = useThemeStore();
  const searchStore = useSearchStore();

  const { icons } = useTheme();

  const { t: tGlobal } = useI18n();
  const router = useRouter();

  const onClickBtnFavorites = () => {
    if (!authStore.user) {
      const favoritesRoute = router.getRoutes().find(r => r.name === 'cp-favorites');

      if (!favoritesRoute) return;

      authStore.setRouteRedirect(favoritesRoute);
      authStore.dialogAuth = true;
    } else {
      router.push({ name: 'cp-favorites' });
    }
  };

  // Scroll to top after fetching results
  watch(
    () => searchStore.loadingState,
    v => {
      if (!v) {
        setTimeout(() => {
          if (Screen.lt.md) {
            themeStore.elPageSearchList?.scrollTo({ top: 0 });
          } else {
            window.scrollTo({ top: 0 });
          }
        }, 100);
      }
    }
  );

  return { ...searchPage, themeStore, tGlobal, onClickBtnFavorites, icons };
};
