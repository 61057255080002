<template>
  <q-page
    :class="{
      'page--search': true,
      'page--search--loading': searchStore.loadingState,
      'page--search--map-only': searchStore.showOnlyMap,
    }"
    :style-fn="themeStore.pageStyleFn"
  >
    <ExpressionLanguageRenderer
      v-for="(c, i) in template"
      :key="i"
      :var-pool="varPool"
      v-bind="c"
    />
  </q-page>

  <Teleport v-if="$q.screen.lt.md" to=".layout-main-footer__body">
    <SPMapToggleWidget class="map-toggle-widget" />
  </Teleport>
</template>

<script setup lang="ts">
import { useDebounceFn, useTitle } from '@vueuse/core';
import { Screen } from 'quasar';
import { computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import ExpressionLanguageRenderer from '@/components/ExpressionLanguageRenderer.vue';
import SPMapToggleWidget from '@/components/SearchPage/Map/SPMapToggleWidget.vue';
import { useBoot } from '@/composables/boot';
import { useSearchPage } from '@/composables/searchPage';
import configApp from '@/config/app.json';
import template from '@/elr/search_page/template.json';
import { useVarPool } from '@/elr/search_page/var_pool';
import metaTranslations from '@/i18n/translations/meta/metaTitles.json';
import useAppStore from '@/store/modules/app';
import useSearchStore from '@/store/modules/search';
import useThemeStore from '@/store/modules/theme';
// eslint-disable-next-line import/no-cycle
import filterMutationsHandler from '@/utils/filterMutationsHandler';

const { locale, t } = useI18n(metaTranslations);
const { bootSearch } = useBoot();
const searchStore = useSearchStore();
const searchPage = useSearchPage();
const themeStore = useThemeStore();
const appStore = useAppStore();
const varPool = useVarPool();
const { filtersMinWidth, searchListWidth, searchHeaderWidth } = searchPage;

const capitalizeFragments = (fragment = '') =>
  fragment
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substring(1))
    .join(' ');

const searchPageTitle = computed(() => {
  const countryText = locale.value === 'el' ? '' : `in ${appStore.configCountry?.name}`;

  let title = `${t(searchStore.searchPageTitleData.title)} ${countryText} | ${
    configApp.company.name
  }`;

  const { fragments } = searchStore.searchPageTitleData;

  // In case of areas with "two level" search only include the neighborhood
  if (
    searchStore.searchPageTitleData.fragments.length > 0 &&
    searchStore.searchPageTitleData.fragments.length <= 2
  ) {
    const neighborhoodFragment = capitalizeFragments(fragments[fragments.length - 1]);

    title = `${t(searchStore.searchPageTitleData.title)}: ${neighborhoodFragment} | ${
      configApp.company.name
    }`;
  }
  // In case of areas with "three level" search include the area and the neighborhood
  else if (searchStore.searchPageTitleData.fragments.length === 3) {
    const neighborhoodFragment = capitalizeFragments(fragments[fragments.length - 1]);
    const areaFragment = capitalizeFragments(fragments[fragments.length - 2]);

    title = `${t(
      searchStore.searchPageTitleData.title
    )}: ${areaFragment} - ${neighborhoodFragment} | ${configApp.company.name}`;
  }
  return title;
});

useTitle(searchPageTitle);

filterMutationsHandler.subscribe();

const onResize = useDebounceFn(() => {
  if (Screen.width < Screen.sizes.md) {
    searchStore.searchAsIMove = true;
  }
}, 100);

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

watch([() => searchStore.showOnlyMap, () => searchStore.searchAsIMove], v => {
  localStorage.setItem('showOnlyMap', JSON.parse(String(v[0])));
  localStorage.setItem('searchAsIMove', JSON.parse(String(v[1])));
});

bootSearch();
</script>

<style lang="scss">
.page--search {
  --search-list-width: v-bind('searchListWidth');
  --search-header-width: v-bind('searchHeaderWidth');
  --filters-min-width: v-bind('filtersMinWidth');
}
</style>
